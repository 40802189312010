import React, { Component } from 'react';
import { NavLink } from "react-router-dom"
import { MDBInput } from "mdbreact";

import "react-toggle/style.css" // for ES6 modules
import '../../assets/css/manage_members.css';

import AssociationTop from '../../components/association-top';
import ModalModifyUser from '../../components/modals/modify-user';
import BootstrapModal from '../../components/modals/bootstrap-modal';
import SpinnerModal from '../../components/modals/spinner-modal';
import TMAdminSideNav from '../../components/tm-admin-sidenav';

import { getAssociationById, getAssociationByToken, getAllRoles, parseAssociationTopFromAssociation, ensureAssociationAdminEmailValues } from '../../helpers';
import { getAuthUsersMe, getUserByUserId, getUsersByAssociationId } from '../../helpers/get';
import { notifyAccountActivatedEmail } from '../../helpers/mailers';
import { updateUserModified } from '../../helpers/put';
import { permitMasterAdminRole, permitAssociationAdminRole, parseRoleNameById, getAssociationMemberRoleId, parseAssociationRolesById } from '../../helpers/role_permissions';

class AAManageMembers extends Component {
    constructor(props) {
        super();
        let user = {
            role_id: null,
            user_id: null,
            username: null,
            confirmed: false,
            blocked: true,
        };

        this.state = { search: "", loading: true, isOpen: false, user, associationRoles: [], result_response: {},
            modalHeader: null, modalBody: null, classNameValue: null, spinnerOn: false };
        this.toggleModal = this.toggleModal.bind(this);
        this.handleUserFieldChange = this.handleUserFieldChange.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    async componentDidMount() {
        const { token } = this.props.match.params;

        const loggedInUser = await getAuthUsersMe();
        const permitMasterAdmin = permitMasterAdminRole(loggedInUser.role.id);
        const permitAssociationAdmin = permitAssociationAdminRole(loggedInUser.role.id);

        let association;
        if (permitMasterAdmin) {
            association = await getAssociationByToken(token);
            association = association[0];
            association = await getAssociationById(association._id);
        } else {
            association = await getAssociationById(loggedInUser.association_id);
        }
        const associationTop = parseAssociationTopFromAssociation(association);
        const allUsers = await getUsersByAssociationId(association._id);

        this.setState({ token, adminUser: association.adminuser, associationTop, permitMasterAdmin, permitAssociationAdmin, allUsers, loading: false });
    }

    onClose() {
        console.log('modal closed...');
    }

    async toggleModal(ev = null) {
        var user_id = {};
        if (ev.currentTarget.value) user_id = JSON.parse(ev.currentTarget.value);
        const modifiedUser = await getUserByUserId(user_id);
        let allRoles = await getAllRoles();
        let associationRoles = parseAssociationRolesById(allRoles.roles, modifiedUser.role.id);

        let user = {
            id: modifiedUser.id,
            role_id: modifiedUser.role.id,
            firstName: modifiedUser.firstName,
            username: modifiedUser.username,
            email: modifiedUser.email,
            confirmed: modifiedUser.confirmed,
            blocked: modifiedUser.blocked,
        };
        this.setState({
            isOpen: !this.state.isOpen,
            spinnerOn: true, // this is required
            user,
            associationRoles
        });
    }

    async handleUserFieldChange(e) {
        this.setState({ spinnerOn: true }); // this is required
        document.getElementById("trigger-spinner-modal").click(); // open spinner

        var body = {};
        var value = '';
        var target = (e.target) ? e.target: e;
        var name = target.name;
        var user_id = target.id.split('_')[1];
        if (name === 'role') {
            value = target.value;
            body[name] = { _id: value }
        } else {
            // value = target.checked; // for checkboxes
            value = target.value;
            body[name] = value;

            // if approving and role is authenticated then force saving role as member
            let { user } = this.state;
            if (name === 'blocked' && value === 'false' && parseRoleNameById(user.role_id) === 'No Role Found') {
                body['role'] = getAssociationMemberRoleId();
            }

        }

        let result = await updateUserModified(body, user_id);
        let result_response = {};
        let result_string = '';
        if (result.status === 200) {
            let unblocked_string = '';
            // notify user if blocked changed to false
            if (name === 'blocked' && value === 'false') {
                let { user, adminUser } = this.state;
                let data = {
                    uid: user_id,
                    association_id: adminUser.association_id,
                    firstname: user.firstName,
                    email: user.email,
                    association_name: this.state.associationTop.name,
                    admin_firstname: adminUser.firstName,
                    admin_lastname: adminUser.lastName,
                    admin_email: adminUser.email,
                };
                data = await ensureAssociationAdminEmailValues(data, adminUser);

                // send account activated email to user
                await notifyAccountActivatedEmail(data);

                unblocked_string = `The user has been notified about account activation.`;
            }
            result_string = `Saved!`;

            // on select change, modify value to role name
            let modalValue = value;
            let modalName = name;
            if (name === 'blocked' || name === 'confirmed' || name === 'role') {
                modalValue = target.options[target.selectedIndex].text;
            }

            if (name === 'blocked') {
                modalName = 'Account Status';
            }

            this.setState({modalHeader: `Saved!`,
                modalBody: `
                Your change of "${modalName}" to "${modalValue}" was saved successfully.
                ${unblocked_string}
                `,
                classNameValue: 'success'
            });
        } else {
            this.setState({modalHeader: `Sorry`,
                modalBody: `
                Something went wrong.
                `,
                classNameValue: 'warning'
            });
            result_string = `Oops. Something went wrong.`;
        }
        document.getElementById("mySpinnerModal").click(); // close spinner
        document.getElementById("trigger-modal").click();
        result_response[`result_${name}`] = result_string;

        let { user } = this.state;
        user[name] = value;
        this.state = { user, result_response };
    }

    renderUsers = (user, i) => {
      const { token, search } = this.state;

      if( search !== "" && user.email.toLowerCase().indexOf( search.toLowerCase() ) === -1 ){
          return null
      }

      let account_status = (user.blocked) ? 'Blocked': 'Approved';

      return (
      <div className="row" key={i}>
          <div className="properties">
              <div className="text p-3">
                  <span className="status"></span>
                  <div className="d-flex">
                      <div className="one">
                          <strong>{user.username}</strong>
                          <br />
                          Association: {user.association_id.name}
                          <br />
                          Email: {user.email}
                          <br />
                          Role: {user.role.name}
                          <br />
                          {/*
                          Confirmed: {user.confirmed.toString()}
                          <br />
                          */}
                          Account Status: {account_status}
                          <br />
                          <br />
                          {this.state.adminUser.id !== user.id ?
                              <span>
                                <button key={i} value={JSON.stringify(user.id)} className="toggle-user-form btn btn-info" onClick={this.toggleModal}>Modify User: {user.username}</button>
                              </span>
                            :
                            <span></span>
                          }
                          <span>
                              <NavLink key={i} to={`/secure/${token}/manage_unit_documents/${user.id}`} className="toggle-user-form btn btn-success ml-2" >Manage Unit {user.unitNumber} Documents</NavLink>
                          </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      );
    };

    renderRoles = (role, i) => {
        return (
            <option key={i} value={role.id}>
                {role.name} {parseRoleNameById(role.id) !== 'No Role Found' ? '(Association Role)': null }
            </option>
        );
    };

    onchange = e => {
      this.setState({ search: e.target.value });
    };

  render() {
      if (this.state.loading) {
          return (
          <div className="App">
            <h1>Loading...</h1>
          </div>
        )
      }
      const { token, allUsers, permitMasterAdmin, permitAssociationAdmin, associationTop, spinnerOn } = this.state;
      if (!permitMasterAdmin && !permitAssociationAdmin) {
          return (
          <div className="App">
            <h1>Sorry. You are not authorized to view this page.</h1>
          </div>
        )
      }

      allUsers.sort((a, b) => (a.username > b.username) ? 1 : -1); // arrange alphabetically by name

    return (
    <div className="App">
        <AssociationTop cbForAssociationTop={associationTop} />

        <section className="ftco-section ftc-no-pb">
            <TMAdminSideNav token={token} />

        <div className="container">
          <div className="row no-gutters slider-text align-items-center justify-content-center">
              <h3 className="mb-3 bread">Manage Members</h3>
          </div>
        </div>

      <section className="ftco-section bg-light">

          <div className="container">
              <MDBInput
                hint="Search Member Email"
                icon="search"
                onChange={this.onchange}
              />
              <br />

        {allUsers.map((user, i) => {
            return this.renderUsers(user, i);
        })}

            <ModalModifyUser show={this.state.isOpen}
              onClose={this.toggleModal}
              >
              {spinnerOn ? <SpinnerModal /> : null}
              <br />
              <strong>{this.state.user.username}</strong>
              <br />
              <form>
                  <div className="form-group">
                      <label htmlFor="role">
                          <span className="toggle-span">Role: (<span className="text-danger">You must assign an Association Role</span>)</span>
                      </label>
                      <select name="role" id={`role_${this.state.user.id}`} className="form-control" defaultValue={this.state.user.role_id} onChange={this.handleUserFieldChange}>
                      {this.state.associationRoles.map((role, i) => {
                          return this.renderRoles(role, i);
                      })}
                      </select>
                  </div>
              {/*
                  <div className="form-group">
                      <label htmlFor="confirmed">
                          <span className="toggle-span">Confirmed: </span>
                      </label>
                      <select name="confirmed" id={`confirmed_${this.state.user.id}`} className="form-control" defaultValue={this.state.user.confirmed} onChange={this.handleUserFieldChange}>
                          <option value="true">Confirmed</option>
                          <option value="false">Unconfirmed</option>
                      </select>
                  </div>
              */}
                  <div className="form-group">
                      <label htmlFor="blocked">
                          <span className="toggle-span">Blocked/Approved: </span>
                      </label>
                      <select name="blocked" id={`blocked_${this.state.user.id}`} className="form-control" defaultValue={this.state.user.blocked} onChange={this.handleUserFieldChange}>
                          <option value="true">Blocked</option>
                          <option value="false">Approved</option>
                      </select>
                  </div>
            </form>
            </ModalModifyUser>
            <input type="hidden" id="trigger-modal" data-toggle="modal" data-target="#myModal" />
            <input type="hidden" id="trigger-spinner-modal" data-toggle="modal" data-target="#mySpinnerModal" />

          </div>
          </section>
          </section>
          <BootstrapModal onClose={this.onClose} cbForBootstrapModal={{header: this.state.modalHeader, body: this.state.modalBody, classNameValue: this.state.classNameValue}}/>

        </div>
    );
  };
}

export default AAManageMembers;
