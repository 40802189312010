import React, { Component } from 'react';
import FileIcon, { defaultStyles } from 'react-file-icon';

import AssociationTop from '../components/association-top';
import AssociationDocumentsNav from '../components/association-documents-nav';
import ModalDisplayDocument from '../components/modals/display-document';

import {
  getJwt, ensureSecureAuthPath, ensurePublicUnAuthPath, requireMatchingUserToken, getAssociationDocumentsCountById,
  getAssociationDocumentsByTypeById, getAssociationUnitsDocumentsCountById, parseAssociationTopFromAssociation
} from '../helpers';

class AssociationDocuments extends Component {
  constructor(props) {
    super();
    this.state = { documents: [], document_tag: null, loading: true, isOpen: false, document_name: null, document_url: null, document_type: null };
  }

  toggleModal = (ev = null) => {
    var values = {};
    if (ev.currentTarget.value) values = JSON.parse(ev.currentTarget.value);
    this.setState({
      isOpen: !this.state.isOpen,
      document_name: values.name || '',
      document_url: values.url || '',
      document_type: values.type || ''
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.document_tag !== prevState.document_tag) {
      return { document_tag: nextProps.match.params.document_tag };
    }
    else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.document_tag !== prevState.document_tag) {
      this.setState({ loading: true });
      console.log('reloading');
      // At this point, we're in the "commit" phase, so it's safe to load the new data.
      this._loadData();
    }
  }

  componentDidMount(prevProps, prevState) {
    console.log('loading');
    this._loadData();
  }

  async _loadData() {
    var jwt = getJwt();
    const { access, token, document_tag, auth_token } = this.props.match.params;
    console.log('token', token);
    console.log('document_tag', document_tag);

    ensureSecureAuthPath(this.props, access, jwt, `association_documents/${token}/${document_tag}`);
    ensurePublicUnAuthPath(this.props, access, jwt, `association_documents/${token}/${document_tag}`, auth_token);
    var association = await requireMatchingUserToken(jwt, this.props, token, `association_documents/`, `/${document_tag}`);
    var associationTop = parseAssociationTopFromAssociation(association);
    let associationDocumentsCount = await getAssociationDocumentsCountById(association._id);
    let documents = await getAssociationDocumentsByTypeById(association._id, document_tag, access, auth_token);
    associationDocumentsCount['token'] = token;

    let associationUnitsDocumentsCount = await getAssociationUnitsDocumentsCountById(association._id);

    this.setState({ documents, associationDocumentsCount, associationUnitsDocumentsCount, associationTop, document_tag, loading: false });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }

    if (this.state.documents.length === 0) return null;
    var { documents, associationDocumentsCount, associationUnitsDocumentsCount, associationTop, document_tag } = this.state;
    var document_tag_title = document_tag.replace(/_/g, ' ').toUpperCase();

    // documents.sort((a, b) => (a.unit_number > b.unit_number) ? 1 : -1); // arrange alphabetically unit # prefix

    return (
      <div className="App">
        <AssociationTop cbForAssociationTop={associationTop} />

        <section className="ftco-section ftc-no-pb">


          <section className="ftco-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-md-12">
                    </div>
                    <div className="col-md-12 Properties-single mt-4 mb-5">
                      <h2>{document_tag_title}</h2>
                    </div>

                    {documents.map((document, i) => {
                      if (document === null) return (<div key={i}>Sorry, There is a problem with a document.</div>);
                      if (document.file === undefined) return (<div key={i}>Sorry, There is a problem with a file.</div>);
                      var clean_ext = document.file.ext.replace('.', '');
                      var values = { url: document.file.url, type: clean_ext, name: document.file.name };

                      return (
                        <div className="col-md-3 d-flex" key={i}>
                          <div className="blog-entry align-self-stretch">
                            <div className="text mt-3 d-block">
                              <div className="meta mb-3">
                              </div>
                              <h3 className="heading mt-3">
                                <button key={i} value={JSON.stringify(values)} onClick={this.toggleModal} >
                                  <div><FileIcon extension={clean_ext} {...defaultStyles[clean_ext]} /></div>
                                  {document.title}
                                </button>
                              </h3>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  </div>
                  <ModalDisplayDocument show={this.state.isOpen}
                    onClose={this.toggleModal}
                  >
                    <iframe width="100%" height="750" title={this.state.document_name} src={`https://docs.google.com/gview?url=${process.env.REACT_APP_API_ENDPOINT}${this.state.document_url}&embedded=true`} type={`application/${this.state.document_type}`} ></iframe>
                  </ModalDisplayDocument>
                </div> {/*<!-- .col-lg-8 -->*/}
                <div className="col-lg-4 sidebar">
                  <AssociationDocumentsNav cbForAssociationDocumentsNav={associationDocumentsCount} associationUnitsDocumentsCount={associationUnitsDocumentsCount} />
                </div>
              </div>
            </div>
          </section>


        </section>
      </div>
    );
  };
}

export default AssociationDocuments;
