import React, { Component } from 'react';
import { NavLink } from "react-router-dom"
import { getJwt } from '../helpers';
import '../assets/css/general.css';

class AssociationTop extends Component {
    constructor(props) {
        super();
        this.state = {  };
    }

    render () {
        var jwt = getJwt();
        var checkedPath = (jwt != null) ? 'secure': 'public';
        let { hero_image_url, token, name } = this.props.cbForAssociationTop;

        var hero_url = (hero_image_url) ? `${process.env.REACT_APP_API_ENDPOINT}${hero_image_url}`: '/images/properties-1.jpg';
        return (
            <div className="hero-wrap association-top" style={{backgroundImage: `url(${hero_url})`}}>
                <div className="overlay"></div>
                <div className="container">
                  <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-9 text-center">
                      <h1 className="mb-3 bread">
                        <NavLink to={`/${checkedPath}/association/${token}`} >{name}</NavLink>
                      </h1>
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

export default AssociationTop;
