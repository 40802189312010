import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '../assets/css/tm-sidenav.css';

import { getAuthUsersMe } from '../helpers/get';
import { permitAccountantRole, permitMasterAdminRole, permitAssociationAdminRole } from '../helpers/role_permissions';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

// instructions: copy full NavItem block to create new menu blocks
class TMAdminSideNav extends Component {
    constructor(props) {
        super();
        let { token } = props;
        this.state = { permitMasterAdmin: null, permitAssociationAdmin: null, token };
    }

    async componentDidMount() {
        const loggedInUser = await getAuthUsersMe();
        if (!loggedInUser.statusCode) {
            const permitAccountant = permitAccountantRole(loggedInUser.role.id);
            const permitMasterAdmin = permitMasterAdminRole(loggedInUser.role.id);
            const permitAssociationAdmin = permitAssociationAdminRole(loggedInUser.role.id);
            this.setState({ permitAccountant, permitMasterAdmin, permitAssociationAdmin });
        }
}

render() {
    let { permitAccountant, permitMasterAdmin, permitAssociationAdmin, token } = this.state;
    if (!permitAccountant && !permitMasterAdmin && !permitAssociationAdmin) {
        return (
        <div id="TMAdminSideNav">
        </div>
      )
    }

    return (
        <div id="TMAdminSideNav">
            <SideNav>
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="admin">
                    <NavItem eventKey="secure">
                        <NavIcon>
                            <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Admin Menu
                        </NavText>
                        <NavItem eventKey={`/secure/${token}/manage_invoices`}>
                            <NavText>
                                <NavLink to={`/secure/${token}/manage_invoices`} className="nav-link" activeClassName='active'>Manage Invoices</NavLink>
                            </NavText>
                        </NavItem>
                        <NavItem eventKey={`/secure/${token}/manage_members`}>
                        {(permitMasterAdmin || permitAssociationAdmin) ?
                            <NavText>
                                <NavLink to={`/secure/${token}/manage_members`} className="nav-link" activeClassName='active'>Manage Members</NavLink>
                            </NavText>
                        : null}
                        </NavItem>
                        <NavItem eventKey={`/secure/${token}/manage_tickets`}>
                        {(permitMasterAdmin || permitAssociationAdmin) ?
                            <NavText>
                                <NavLink to={`/secure/${token}/manage_tickets`} className="nav-link" activeClassName='active'>Manage Tickets</NavLink>
                            </NavText>
                        : null}
                        </NavItem>
                        <NavItem eventKey={`/secure/${token}/manage_uploads`}>
                        {(permitMasterAdmin || permitAssociationAdmin) ?
                            <NavText>
                                <NavLink to={`/secure/${token}/manage_uploads`} className="nav-link" activeClassName='active'>Manage Uploads</NavLink>
                            </NavText>
                        : null}
                        </NavItem>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        </div>
        )
    }
}

export default TMAdminSideNav;
