import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

import AssociationTop from '../../components/association-top';
import TMAdminSideNav from '../../components/tm-admin-sidenav';

import { getAssociationById, getAssociationByToken, parseAssociationTopFromAssociation } from '../../helpers';
import { getAuthUsersMe } from '../../helpers/get';
import { permitMasterAdminRole, permitAssociationAdminRole } from '../../helpers/role_permissions';

class Admin extends Component {
    constructor(props) {
        super();
        this.state = { loading: true };
    }

    async componentDidMount() {
        const { token } = this.props.match.params;
        const loggedInUser = await getAuthUsersMe();
        const permitMasterAdmin = await permitMasterAdminRole(loggedInUser.role.id);
        const permitAssociationAdmin = await permitAssociationAdminRole(loggedInUser.role.id);

        let association;
        if (permitMasterAdmin) {
            association = await getAssociationByToken(token);
            association = association[0];
            association = await getAssociationById(association._id);
        } else {
            association = await getAssociationById(loggedInUser.association_id);
        }
        const associationTop = await parseAssociationTopFromAssociation(association);
        this.setState({ token, associationTop, permitMasterAdmin, permitAssociationAdmin, loading: false });
    }

    render () {
        if (this.state.loading) {
            return (
            <div className="App">
              <h1>Loading...</h1>
            </div>
          )
        }

        var { token, associationTop, permitMasterAdmin, permitAssociationAdmin } = this.state;
        if (!permitMasterAdmin && !permitAssociationAdmin) {
            return (
            <div className="App">
              <h1>Sorry. You are not authorized to view this page.</h1>
            </div>
          )
        }
        return (
        <div className="App">
            <AssociationTop cbForAssociationTop={associationTop} />

            <section className="ftco-section ftc-no-pb">
                <TMAdminSideNav token={token} />

    			<div className="container">
                    <div className="heading-section heading-section-wo-line mb-5 pl-md-5">
                        <div className="pl-md-5 ml-md-5">
                            <h2 className="mb-4">Association Administration</h2>
                        </div>
                    </div>
                    <div className="pl-md-5 ml-md-5 mb-5">
                        <ul className="navbar-nav ml-auto">
                        <li className="nav-item"><NavLink to={`/secure/${token}/manage_members`} className="nav-link" activeClassName='active'>Manage Members</NavLink></li>
                        <li className="nav-item"><NavLink to={`/secure/${token}/manage_uploads`} className="nav-link" activeClassName='active'>Manage Uploads</NavLink></li>
                        </ul>
                    </div>
    			</div>
    		</section>
        </div>
        );
    }
}

export default Admin;
