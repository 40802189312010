import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getJwt } from '../helpers';
import { doLogout } from '../helpers/auth';
import { getAuthUsersMe } from '../helpers/get';
import { parseRoleNameById } from '../helpers/role_permissions';

class AuthComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined
        };
    }

    async componentDidMount() {
        if (this.props.location.pathname === '/logout') {
            doLogout();
        }

        this.getUser();
        this.checkRoles();
    }

    async checkRoles() {
        const loggedInUser = await getAuthUsersMe();
        if (parseRoleNameById(loggedInUser.role.id) === 'No Role Found') {
            doLogout();
        }
    }

    async getUser() {
        const jwt = getJwt();
        if (jwt === null) {
            this.setState({
                user: null
            });
            localStorage.removeItem('hoaoa-jwt');
            localStorage.removeItem('hoaoa-user');
            this.props.history.push('/login');
            return;
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(AuthComponent);
