
import React, { Component } from 'react';
import { Redirect } from "react-router-dom"
import { getJwt, getAssociationByToken } from '../helpers';
var jwt = getJwt();

class SearchToken extends Component {
    constructor(props) {
        super();
        this.state = { search_token: '', submitted: false };
    }

    async componentDidMount() {
        if (jwt !== null) {
            var el = document.getElementById('search-token');
            el.classList.add("hide");
        }
    }

    handleSubmit = async (event) => {
        // dsc, chi
        event.preventDefault();
        const { search_token } = this.state;
        let response = await getAssociationByToken(search_token.toLowerCase());
        // if (search_token.includes("-")) {
        // response = await getAssociationByToken(search_token.toLowerCase());
        // } else {
        //     response = await getAssociationsByZipcode(search_token);
        // }

        this.setState({
            search_token: '', submitted: true, associations: response,
            tokenNotFound: (response.length === 0) ? search_token : null
        });
    };

    renderAssociationInfo(props) {
        const { tokenNotFound, associations, search_token } = this.state;
        let parts = window.location.pathname.split('/');
        let url_token = parts.pop();

        if (tokenNotFound) {
            return (<span style={{ color: 'red', paddingLeft: '20px' }}>No results for {tokenNotFound}</span>);
        } else if (url_token === search_token && search_token.length >= 3) {
            return (<span style={{ color: 'yellow', paddingLeft: '20px' }}>Already viewing {url_token}</span>);
        } else {
            let checkedPath = (jwt !== null) ? 'secure' : 'public';
            if (associations.length === 1) {
                if (parts[2] !== 'association') {
                    return <Redirect to={`/${checkedPath}/association/${associations[0].token}`} />
                } else {
                    window.location.replace(`/${checkedPath}/association/${associations[0].token}`);
                }
            }

        }
    }

    render() {
        const { search_token } = this.state;
        return (
            <div>
                <section id="search-token" className="ftco-search">
                    <div className="container">
                        <div className="col-md-12 search-wrap">
                            <form onSubmit={this.handleSubmit} className="search-property" >
                                <div className="col-md align-items-end">
                                    <div className="form-group">
                                        <div className="form-field">
                                            <div className="icon"><span className="icon-search"></span></div>
                                            <input
                                                autoFocus
                                                id="search-token-input"
                                                className="form-control"
                                                type="text"
                                                value={search_token}
                                                onChange={event => this.setState({ search_token: event.target.value })}
                                                placeholder="Search by Association ID/Initials (i.e. Bonita Beach Condominiums = “BBC”)"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {this.state.submitted && this.renderAssociationInfo()}
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default SearchToken;
