import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/bootstrap.css';

// this is a bootstrap modal

class BootstrapModal extends Component {
  render() {
    let { header, body, classNameValue, customID } = this.props.cbForBootstrapModal;
    if (customID === undefined) customID = "myModal";
    let dialogClass = `modal-dialog modal-xl border border-${classNameValue}`;
    let headerClass = `modal-header bg-${classNameValue}`;

    return (
        <div id={customID} className="modal fade" role="dialog" tabIndex="-1">
          <div className={dialogClass}>
            <div className="modal-content">

              <div className={headerClass}>
                <h4 className="modal-title">{header}</h4>
                <button type="button" className="close" data-dismiss="modal" onClick={this.props.onClose} >&times;</button>
              </div>

              <div className="modal-body">
                <p>{body}</p>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.props.onClose} >Close</button>
              </div>

            </div>
          </div>
        </div>
    );
  }
}

BootstrapModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  // show: PropTypes.bool,
  // children: PropTypes.node
};

export default BootstrapModal;
