import { getJwt } from '../helpers';
const method = 'POST';
const jwt = getJwt();

export async function notifyAccountActivatedEmail(body) {
    var data = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/notify_account_activated`, data);
    return response;
}

export async function notifyNewInvoiceEmail(body) {
    var data = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/notify_new_invoice`, data);
    return response;
}

export async function notifyNewTicketEmail(body) {
    var data = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/tickets/notify_new_ticket`, data);
    return response;
}

export async function notifyTicketStatusChanged(body) {
    var data = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/tickets/notify_ticket_status_changed`, data);
    return response;
}

export async function notifyDocumentUploaded(body) {
    var data = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/notify_document_uploaded`, data);
    return response;
}
