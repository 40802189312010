import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

import "react-toggle/style.css" // for ES6 modules
import '../../assets/css/manage_members.css';

import AssociationTop from '../../components/association-top';
import NavButton from '../../components/nav-button';
import TMAdminSideNav from '../../components/tm-admin-sidenav';
import TMMemberSideNav from '../../components/tm-member-sidenav';

import { getTicketTypes, getAssociationById, getAssociationByToken, parseAssociationTopFromAssociation,
    parseTicketsByType, parseDateTime } from '../../helpers';
import { getAuthUsersMe, getTicketsByAssociationId } from '../../helpers/get';
import { permitAccountantRole, permitMasterAdminRole } from '../../helpers/role_permissions';

class AAManageInvoices extends Component {
    constructor(props) {
        super();
        this.state = { search: "", loading: true, isOpen: false, allRoles: [], result_response: {} };
        this.onClickDelete = this.onClickDelete.bind(this);
    }

    async componentDidMount() {
        const { token } = this.props.match.params;

        const loggedInUser = await getAuthUsersMe();
        const permitAccountant = permitAccountantRole(loggedInUser.role.id);
        const permitMasterAdmin = permitMasterAdminRole(loggedInUser.role.id);
        let tickets = await getTicketsByAssociationId(loggedInUser.association_id);

        // prevent display of tkts if user not permitted
        if (!permitMasterAdmin && !permitAccountant) {
            tickets = [];
        }

        let association;
        if (permitMasterAdmin) {
            association = await getAssociationByToken(token);
            association = association[0];
            association = await getAssociationById(association._id);
        } else {
            association = await getAssociationById(loggedInUser.association_id);
        }
        const associationTop = parseAssociationTopFromAssociation(association);
                        
        var allTicketsByType = parseTicketsByType(tickets);
        var ticketTypes = getTicketTypes();

        this.setState({ token, associationTop, ticketTypes, allTicketsByType, permitMasterAdmin, permitAccountant, loading: false });
    }

    async onClickDelete(ev = null) {
        document.getElementById("trigger-spinner-modal").click(); // open spinner
        let values = {};
        if (ev.currentTarget.value) values = JSON.parse(ev.currentTarget.value);
        this.setState({modalHeader: `Are you sure?`,
            modalBody: `
            Please confirm you want to delete file "${values.name}".
            `,
            classNameValue: 'warning'
        });
        document.getElementById(`trigger-modal-${values.document_file_id}`).click();
    }

    renderTicket = (invoice, i) => {
        if (this.state.loading) {
            return (
            <div className="App">
              <h1>Loading...</h1>
            </div>
          )
        }
        var { token, search } = this.state;

        if( search !== "" && invoice.title.toLowerCase().indexOf( search.toLowerCase() ) === -1 ){
          return null
        }

        if (invoice === null) return (<div key={i}>null</div>);
        let username = (invoice.created_by_user) ? invoice.created_by_user.username: 'user not found';
        return (
        <div className="row" key={i}>
            <div className="properties">
                <div className="text p-3">
                    <span className="status"></span>
                    <div className="d-flex">
                        <div className="one">
                            <strong>{invoice.title}</strong>
                            <br />
                            Created: {parseDateTime(invoice.createdAt)}
                            <br />
                            Updated: {parseDateTime(invoice.updatedAt)}
                            <br />
                            Created By: {username}
                            <br />
                            <br />
                            <NavButton token={token} access="s" toPage={`manage_invoices/view/invoice/${invoice._id}`} type={`view`} text={`View`} />
                            {this.state.permitAssociationAdmin ?
                                <NavLink to={`/secure/${token}/manage_invoices/edit/invoice/${invoice._id}`} className="btn btn-warning m-2" >
                                    Edit
                                </NavLink>
                            : null}

                            {/*
                            <NavLink to={`/secure/${token}/manage_invoices/delete/${invoice._id}`} className="btn btn-danger" >
                                Delete
                            </NavLink>
                            */}
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    };

    renderTicketType = (ticketType, i) => {
        if (this.state.loading) {
            return (
            <div className="App">
              <h1>Loading...</h1>
            </div>
          )
        }

        var { allTicketsByType, search, permitMasterAdmin, permitAccountant } = this.state;

        if (!permitMasterAdmin && !permitAccountant) {
            return (
                <div className="App">
                    <h1>Sorry. You are not authorized to view this page.</h1>
                </div>
            )
        }

        if((search !== ""  && !ticketType.slug.length) ||
        (search !== "" && ticketType.slug.toLowerCase().indexOf( search.toLowerCase() ) === -1)){
            return null
        }

        var ticketTypeLength = (!allTicketsByType[ticketType.slug]) ? 0: allTicketsByType[ticketType.slug].length;
        return (
            <div key={i}>
                <div className="row no-gutters slider-text align-items-left">
                    <h4 className="mb-3 bread">{ticketType.fullName} ({ticketTypeLength})</h4>
                </div>

                {
                (!ticketTypeLength) ?
                    <div>
                    </div>
                :
                allTicketsByType[ticketType.slug].map((ticket, i) => {
                    return this.renderTicket(ticket, i);
                })};
            </div>
        );
    };

    onchange = e => {
      this.setState({ search: e.target.value });
    };

  render() {
      if (this.state.loading) {
          return (
          <div className="App">
            <h1>Loading...</h1>
          </div>
        )
      }

      var { token, associationTop, ticketTypes, permitMasterAdmin, permitAssociationAdmin, permitAssociationMember } = this.state;
      ticketTypes.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1); // arrange alphabetically by name

      if (!permitMasterAdmin && !permitAssociationAdmin && !permitAssociationMember ) {
          return (
          <div className="App">
            <h1>Sorry. You are not authorized to view this page.</h1>
          </div>
        )
      }

    return (
    <div className="App">

        <AssociationTop cbForAssociationTop={associationTop} />
        <section className="ftco-section ftc-no-pb">
            <TMAdminSideNav token={token} />
            <TMMemberSideNav />

        <div className="container">
          <div className="row no-gutters slider-text align-items-center justify-content-center">
              <h3 className="mb-3 bread">Manage Invoices</h3>
          </div>
        </div>

      <section className="ftco-section bg-light">

          <div className="container">
              {/* <MDBInput
                hint="Search Invoice Number"
                icon="search"
                onChange={this.onchange}
              /> */}
              <br />
              <NavLink to={`/secure/${token}/manage_invoices/add/invoice`} className="btn btn-success" role="button">Create New Invoice</NavLink>
              <br />

        {/* {ticketTypes.map((ticketType, i) => {
            return this.renderTicketType(ticketType, i);
        })} */}

          </div>
          </section>
          </section>

        </div>
    );
  };
}

export default AAManageInvoices;
