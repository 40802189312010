import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '../assets/css/tm-sidenav.css';

import { getAuthUsersMe } from '../helpers/get';
import { permitAssociationMemberRole } from '../helpers/role_permissions';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

// instructions: copy full NavItem block to create new menu blocks
class TMMemberSideNav extends Component {
    constructor(props) {
        super();
        let { token } = props;
        this.state = { loggedInUser: [], token };
    }

    async componentDidMount() {
        const loggedInUser = await getAuthUsersMe();
        const permitAssociationMember = permitAssociationMemberRole(loggedInUser.role.id);
        this.setState({ permitAssociationMember });
    }

    render() {
        const { token, permitAssociationMember } = this.state;
        if (!permitAssociationMember) {
            return (
            <div id="TMMemberSideNav">
            </div>
          )
        }
    
        return (
            <div id="TMMemberSideNav">
                <SideNav>
                    <SideNav.Toggle />
                    <SideNav.Nav defaultSelected="admin">
                        <NavItem eventKey="secure">
                            <NavIcon>
                                <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                            </NavIcon>
                            <NavText>
                                Member Menu
                            </NavText>
                            <NavItem eventKey={`/secure/${token}/manage_tickets`}>
                                <NavText>
                                    <NavLink to={`/secure/${token}/manage_tickets`} className="nav-link" activeClassName='active'>Manage Tickets</NavLink>
                                </NavText>
                            </NavItem>
                        </NavItem>
                    </SideNav.Nav>
                </SideNav>
            </div>
        )
    }
}

export default TMMemberSideNav;
