import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import '../assets/css/dropdown.css';
import '../assets/css/login.css';
import BootstrapModal from '../components/modals/bootstrap-modal';
import SpinnerModal from '../components/modals/spinner-modal';

import { getSelectableAssociations, getAssociationByToken, ensureAssociationAdminEmailValues } from '../helpers';
import { getAssociationAdministrator } from '../helpers/get';

class Register extends Component {
    constructor(props) {
        super();

        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            unitNumber: '',
            token: '',
            password: '',
            confirmPassword: '',
            hasAgreed: false,
            data: [],
            spinnerOn: false,
            loading: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    async componentDidMount() {
        const responseTOS = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/pages/5eac1be77935e72ea4479af7`);
        const jsonTOS = await responseTOS.json();

        const json = await getSelectableAssociations();
        this.setState({ data: json, tos: jsonTOS, loading: false });
        this.setState({ spinnerOn: true }); // this is required
    }

    onClose() {
        console.log('modal closed...');
    }

    handleChange(e) {
        var target = (e.target) ? e.target: e;
        var value = '';
        var name = '';
        if (target.label) {
            value = target.label.split(' - ')[0];
            name = 'token';
        } else {
            value = target.type === 'checkbox' ? target.checked : target.value;
            name = target.name;
        }

        this.setState({
          [name]: value
        });
    }

    handleBlur(e) {
        this.confirmPassword(e);
    }

    async handleSubmit(e) {
        e.preventDefault();
        document.getElementById("trigger-spinner-modal").click(); // open spinner

        var passwordConfirmed = await this.confirmPassword(e);
        if (!passwordConfirmed) return;

        // get association_id per submitted token
        // console.log('this.state.token', this.state.token);
        const association = await getAssociationByToken(this.state.token);
        // console.log('association', association);
        if (!association.length) {
            alert("Missing association...");
            return;
        }

        // create user object
        // const user = { // test user
        //     username: 'bobobobob',
        //     email: 'howiek03@yahoo.com',
        //     password: '2222222',
        //     association_id: "5db09c0fbdeb37682d1d76a6",
        //     firstName: "how",
        //     lastName: "kov",
        //     unitNumber: "222",
        //     hasAgreed: true,
        //     blocked: true,
        //     confirmed: false,
        // }

        const user = {
            username: this.state.email,
            email: this.state.email,
            password: this.state.password,
            association_id: association[0]._id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            unitNumber: this.state.unitNumber,
            hasAgreed: this.state.hasAgreed,
            blocked: true,
            confirmed: false,
        }

        // console.log('The form was submitted with the following data:');
        // console.log('user', user);

        try {
            let result = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/local/register`, user);
            // console.log('result 1', result);

            if (result.status === 200) {
                this.setState({modalHeader: `Registered!`,
                    modalBody: `
                    Thank you for registering. You will be notified when your association administrator has activated your account.
                    `,
                    classNameValue: 'success'
                });

                // console.log('association', association);
                let adminuser = await getAssociationAdministrator(user.association_id);
                // console.log('adminuser', adminuser);
                let data = {
                    uid: "5ed7b6127438b53149b9383b",
                    association_id: user.association_id,
                    firstname: user.firstName,
                    email: user.email,
                    association_name: association[0].name,
                    admin_firstname: adminuser.firstName,
                    admin_lastname: adminuser.lastName,
                    admin_role: adminuser.role,
                    admin_email: adminuser.email,
                };
                data = await ensureAssociationAdminEmailValues(data, adminuser);

                // send registration confirmation to user
                await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/associations/registration_welcome`, data);

                // send registration notification to admin
                await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/associations/notify_new_registration`, data);

                // window.location.replace(`/`); // redirect user to home page
                // this.props.history.push('/association/'); // forward the user to his assigned association
            } else {
                this.setState({modalHeader: `Sorry`,
                    modalBody: `
                    Registration failed. Please contact your administrator.
                    `,
                    classNameValue: 'danger'
                });
                console.log('result failure', result);
            }
        } catch (error) {
            console.log('error', error);
            console.log('error.response', error.response);
            // console.log('error messages', error.response.data['message'][0]['messages']);
            let modalBody = '';
            if (error.response !== undefined) {
                if (error.response.status === 500) {
                    this.setState({modalHeader: `Error ${error.response.status}`,
                    modalBody: error.response.data.message,
                    classNameValue: 'danger'
                    });
                } else {
                    for (let message of error.response.data['message'][0]['messages']) {
                        console.log('message', message);
                        modalBody += `${message.message}. `
                    }
                    this.setState({modalHeader: `Error ${error.response.status}`,
                    modalBody,
                    classNameValue: 'danger'
                    });
                }
            }
        }
        document.getElementById("mySpinnerModal").click(); // close spinner
        document.getElementById("trigger-status-modal").click();
    }

    confirmPassword(e) {
        const { password, confirmPassword } = this.state;
        if (confirmPassword.length === password.length) {
            if (password !== confirmPassword) {
                alert("Passwords don't match");
                e.preventDefault();
                return false;
            }
            return true;
        }
        return false;
    }

    render() {
        if (this.state.loading) {
            return (
            <div className="App">
              <h1>Loading...</h1>
            </div>
          )
        }
        const {  spinnerOn } = this.state;

        return (
        <div className="FormCenter App">
            {spinnerOn ? <SpinnerModal /> : null}
            <br />
            Please sign up below or<Link to="/login" className="FormField__Link">Log In</Link>
        <form ref="register_form" onSubmit={this.handleSubmit} className="FormFields" autoComplete="off">

                <div className="FormField">
                  <label className="FormField__Label" htmlFor="firstName">First Name</label>
                  <input autoFocus type="text" id="firstName" className="FormField__Input" required placeholder="Enter your first name" name="firstName" value={this.state.firstName} onChange={this.handleChange} />
                </div>

                <div className="FormField">
                  <label className="FormField__Label" htmlFor="lastName">Last Name</label>
                  <input type="text" id="lastName" className="FormField__Input" required placeholder="Enter your last name" name="lastName" value={this.state.lastName} onChange={this.handleChange} />
                </div>

                <div className="FormField" id="react-dropdown-tree-select">
                    <label className="FormField__Label">Choose Your Association</label>
                    <DropdownTreeSelect data={this.state.data} mode="radioSelect" texts={{ placeholder: 'Search by Zipcode or Name' }} type="DropdownTreeSelect" onChange={this.handleChange} />
                    <input type="text" id="token" className="FormField__Input" required name="token" readOnly value={this.state.token} />
                </div>

                <div className="FormField">
                  <label className="FormField__Label" htmlFor="name">Unit Number</label>
                  <input type="text" id="unitNumber" className="FormField__Input" required placeholder="Enter your Unit Number" name="unitNumber" value={this.state.unitNumber} onChange={this.handleChange} />
                </div>

                <div className="FormField">
                    <label className="FormField__Label" htmlFor="email">E-Mail Address</label>
                    <input type="email" id="email" className="FormField__Input" required placeholder="Enter your email" name="email" value={this.state.email} onChange={this.handleChange} />
                </div>

                <div className="FormField">
                    <label className="FormField__Label" htmlFor="password">Password</label>
                    <input type="password" id="password" className="FormField__Input" required placeholder="Enter your password" name="password" onChange={this.handleChange} onBlur={(event)=>this.handleBlur(event)} />
                </div>

                <div className="FormField">
                    <label className="FormField__Label" htmlFor="confirmPassword">Confirm Password</label>
                    <input type="password" id="confirmPassword" className="FormField__Input" required placeholder="Confirm your password" name="confirmPassword" onBlur={(event)=>this.handleBlur(event)} onChange={this.handleChange} />
                </div>

                <div className="FormField">
                    <label className="FormField__CheckboxLabel">
                        <input className="FormField__Checkbox" required type="checkbox" name="hasAgreed" value={this.state.hasAgreed} onChange={this.handleChange} /> I agree to the <button type="button" className="btn btn-notice btn-sm" data-toggle="modal" data-target="#tos-modal">terms of service</button>
                    </label>
                </div>

                <div className="FormField">
                  <button type="submit" className="FormField__Button mr-20">Sign Up</button>
                </div>
                <input type="hidden" id="trigger-status-modal" data-toggle="modal" data-target="#status-modal" />
                <input type="hidden" id="trigger-spinner-modal" data-toggle="modal" data-target="#mySpinnerModal" />

            </form>
            <BootstrapModal onClose={this.onClose} cbForBootstrapModal={{header: this.state.tos.Title, body: this.state.tos.body, customID: "tos-modal"}}/>
            <BootstrapModal onClose={this.onClose} cbForBootstrapModal={{header: this.state.modalHeader, body: this.state.modalBody, customID: "status-modal", classNameValue: this.state.classNameValue}}/>
          </div>
        );
    }
}

export default Register;
