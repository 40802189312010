import { getJwt, sanitizeResponse } from '../helpers';
import { updateAssociationWithModifiedComponent } from '../helpers/put';
const method = 'POST';
const jwt = getJwt();

export async function postUploadAssociationDocument(formData, association) {
    var data = {
        method,
        headers: {
            'Authorization': jwt,
        },
        body: formData
    };
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/upload`, data);
    // console.log('response', response);
    if (response.status === 200) {
        const response2 = await response.json();
        // console.log('response2', response2);
        // console.log('pre association', association);

        // push newComponent into association object correct component location
        let newComponent = {};
        newComponent['notifyMembers'] = formData.get('notifyMembers');
        newComponent['title'] = formData.get('title');
        newComponent['file'] = response2[0].id;
        // console.log('newComponent', newComponent);
        let typeComponent = formData.get('type');
        if (typeComponent === 'unit_documents') {
            newComponent['unit_number'] = formData.get('unit_number');
        }
        // console.log('typeComponent', typeComponent);
        // console.log('test 1 association[typeComponent]', association[typeComponent]);

        // delete null component incorrectly created by file upload
        association[typeComponent] = association[typeComponent].filter(function (el) {
            return el != null;
        });
        // console.log('test 2 association[typeComponent]', association[typeComponent]);

        association[typeComponent].push(newComponent);
        // console.log('post association', association);

        const response3 = await updateAssociationWithModifiedComponent(association);
        // console.log('response3', response3);
        return {message: "response3", status: response3.status};
    }
    // console.log('response', response.status);
    return {message: "response", status: response.status};
}

export async function postUploadUnitDocument(formData) {
    var data = {
        method,
        headers: {
            'Authorization': jwt,
        },
        body: formData
    };
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/upload`, data);
    // console.log('response', response);
    if (response.status === 200) {
        const response2 = await response.json();
        // console.log('response2', response2);

        let unit_document = {};
        unit_document['type'] = formData.get('type');
        unit_document['association'] = formData.get('association');
        unit_document['user'] = formData.get('user');
        unit_document['title'] = formData.get('title');
        unit_document['notifyMembers'] = formData.get('notifyMembers');
        unit_document['file'] = response2[0].id;
        // console.log('post unit_document', unit_document);

        const response3 = await postCreateUnitDocument(unit_document);
        // console.log('response3', response3);
        return {message: "response3", status: response3.status};
    }
    // console.log('response', response.status);
    return {message: "response", status: response.status};
}

export async function postCreateUnitDocument(body) {
    var data = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(body)
    };
    let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/unit-documents`, data);
    // console.log('response', response);
    let response2 = await response.json();
    let message = sanitizeResponse(response2);
    // console.log('message', message);
    return {message, status: response.status};
}

export async function postCreateTicket(body) {
    var data = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(body)
    };
    let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/tickets`, data);
    // console.log('response', response);
    let response2 = await response.json();
    let message = sanitizeResponse(response2);
    // console.log('message', message);
    return {message, status: response.status};
}
