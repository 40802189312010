import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

// import "react-toggle/style.css" // for ES6 modules
import '../../../assets/css/form.css';

import AssociationTop from '../../../components/association-top';
import NavButton from '../../../components/nav-button';
import BootstrapModal from '../../../components/modals/bootstrap-modal';
import SpinnerModal from '../../../components/modals/spinner-modal';
import TMAdminSideNav from '../../../components/tm-admin-sidenav';
import TMMemberSideNav from '../../../components/tm-member-sidenav';

import {
  ensureAssociationAdminEmailValues, getAssociationById, parseAssociationTopFromAssociation,
  getTicketTypes, getTicketStatuses, parseValueBySlug
} from '../../../helpers';
import { getAuthUsersMe, getTicketById, getAssociationTeamUsers } from '../../../helpers/get';
import { notifyTicketStatusChanged } from '../../../helpers/mailers';
import { updateTicketById } from '../../../helpers/put';
import { permitMasterAdminRole, permitAssociationAdminRole, parseRoleNameById } from '../../../helpers/role_permissions';

class AAEditTicket extends Component {
  constructor(props) {
    super();
    this.state = { loading: true, spinnerOn: false, created_by_user: null, type: null, status: null, assigned_to: null, title: null, description: null };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  async componentDidMount() {
    const { token, id } = this.props.match.params;

    const loggedInUser = await getAuthUsersMe();
    const permitMasterAdmin = await permitMasterAdminRole(loggedInUser.role.id);
    const permitAssociationAdmin = await permitAssociationAdminRole(loggedInUser.role.id);
    const association = await getAssociationById(loggedInUser.association_id);
    const associationTeamUsers = await getAssociationTeamUsers(loggedInUser.association_id);

    const associationTop = await parseAssociationTopFromAssociation(association);
    const ticket = await getTicketById(id);
    if (ticket.assigned_to === undefined) ticket.assigned_to = { id: null }; // set default in case not yet assigned

    this.setState({
      token, associationTop, permitMasterAdmin, permitAssociationAdmin, adminuser: association.adminuser,
      loggedInUser, ticket,
      created_by_user: ticket.created_by_user, type: ticket.type, status: ticket.status, assigned_to: ticket.assigned_to, title: ticket.title, description: ticket.description,
      associationTeamUsers: associationTeamUsers.result, loading: false, spinnerOn: true
    });
  }

  renderOptions = (ticketType, i) => {
    return (
      <option key={i} value={ticketType.slug}>
        {ticketType.fullName}
      </option>
    );
  };

  renderTeamUsers = (teamUser, i) => {
    let role = parseRoleNameById(teamUser.roleId);
    return (
      <option key={i} value={teamUser.id}>
        {teamUser.firstName} {teamUser.lastName} ({role})
      </option>
    );
  };

  onClose() {
    window.location.replace(`/secure/${this.state.token}/manage_tickets`);
  }

  async onFormSubmit(e) {
    e.preventDefault() // Stop form submit
    this.refs.btn.setAttribute("disabled", "disabled");
    this.setState({ spinnerOn: true }); // this is required
    document.getElementById("trigger-spinner-modal").click(); // open spinner

    let { ticket, type, status, assigned_to, title, description, adminuser, loggedInUser, associationTop } = this.state;
    var body = { id: ticket.id, assigned_to, type, status, title, description };
    let result = await updateTicketById(body);
    if (result.status === 200) {
      var ticketStatuses = getTicketStatuses();
      let ticket_status = parseValueBySlug(ticketStatuses, status, 'fullName');

      // send email to ticket creator
      let custom_body = (status === 'completed') ? `Your request has been completed. Please let us know if you have any questions.` : `Thank you for your submission. Your request has been ${ticket_status}.`;
      let data = {
        uid: loggedInUser.id,
        association_id: loggedInUser.association_id,
        association_name: associationTop.name,
        first_name: loggedInUser.firstName,
        email: loggedInUser.email,
        ticket_type: type,
        ticket_id: result.message.id,
        ticket_status,
        custom_body,
      };
      data = await ensureAssociationAdminEmailValues(data, adminuser);
      notifyTicketStatusChanged(data);

      this.setState({
        modalHeader: `Success!`,
        modalBody: `
                Your ticket was updated successfully.
                `,
        classNameValue: 'success'
      });
    } else {
      this.setState({
        modalHeader: `Sorry`,
        modalBody: `
                Your ticket was NOT updated.
                `,
        classNameValue: 'warning'
      });
      console.log('result', result);
    }
    document.getElementById("mySpinnerModal").click(); // close spinner
    document.getElementById("trigger-modal").click();
    this.setState({ spinnerOn: false });
    this.refs.btn.removeAttribute("disabled");
  }

  async onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }

    const { token, associationTeamUsers, associationTop, ticket, spinnerOn, permitMasterAdmin, permitAssociationAdmin } = this.state;
    if (!permitMasterAdmin && !permitAssociationAdmin) {
      return (
        <div className="App">
          <h1>Sorry. You are not authorized to view this page.</h1>
        </div>
      )
    }

    var ticketTypes = getTicketTypes();
    ticketTypes.sort((a, b) => (a.slug > b.slug) ? 1 : -1); // arrange alphabetically by slug
    var ticketStatuses = getTicketStatuses();
    ticketStatuses.sort((a, b) => (a.slug > b.slug) ? 1 : -1); // arrange alphabetically by slug

    return (
      <div className="App">
        <AssociationTop cbForAssociationTop={associationTop} />

        <section className="ftco-section ftc-no-pb">
          <TMAdminSideNav token={token} />
          <TMMemberSideNav />

          <section className="ftco-section bg-light">
            <div className="container">
              <div className="row">
                <div className="properties">
                  <span className="status"></span>
                  <div className="card" style={{ width: '69rem' }}>
                    <div className="card-body">
                      <p className="card-text">Created By: {ticket.created_by_user.username}</p>
                      <form onSubmit={this.onFormSubmit} id="ticket_form">
                        <div className="form-group">
                          <label htmlFor="type">Ticket Type:</label><br />
                          <select required name="type" className="custom-select" defaultValue={ticket.type} onChange={this.onChange}>
                            <option value="">-- Please Choose --</option>
                            {ticketTypes.map((ticketType, i) => {
                              return this.renderOptions(ticketType, i);
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="status">Ticket Status:</label><br />
                          <select required name="status" className="custom-select" defaultValue={ticket.status} onChange={this.onChange}>
                            <option value="">-- Please Choose --</option>
                            {ticketStatuses.map((ticketStatus, i) => {
                              return this.renderOptions(ticketStatus, i);
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="assigned_to">Assigned To:</label><br />
                          <select required name="assigned_to" className="custom-select" defaultValue={ticket.assigned_to.id} onChange={this.onChange}>
                            <option value="">-- Please Choose --</option>
                            {associationTeamUsers.map((teamUser, i) => {
                              return this.renderTeamUsers(teamUser, i);
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="title">Title:</label>
                          <input name="title" type="title" className="form-control" placeholder="Enter title" id="title" required minLength="10" defaultValue={ticket.title} onChange={this.onChange} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="title">Description:</label>
                          <textarea name="description" type="description" className="form-control" placeholder="Enter description" id="description" required minLength="100" defaultValue={ticket.description} onChange={this.onChange}></textarea>
                        </div>
                        <button ref="btn" type="submit" className="FormField__Button mr-20">Submit</button>
                        <input type="hidden" id="trigger-modal" data-toggle="modal" data-target="#myModal" />
                        <input type="hidden" id="trigger-spinner-modal" data-toggle="modal" data-target="#mySpinnerModal" />
                        {spinnerOn ? <SpinnerModal /> : null}
                      </form>

                      <NavButton token={token} access="s" toPage={`manage_tickets`} type={`viewList`} text={`View List`} />
                      <NavLink to={`/secure/${token}/manage_tickets/view/ticket/${ticket._id}`} className="btn btn-warning m-2" >
                        View
                                </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <BootstrapModal onClose={this.onClose} cbForBootstrapModal={{ header: this.state.modalHeader, body: this.state.modalBody, classNameValue: this.state.classNameValue }} />
      </div>
    );
  };
}

export default AAEditTicket;
