import React, { Component } from 'react';

// import "react-toggle/style.css" // for ES6 modules
import '../../../assets/css/form.css';

import AssociationTop from '../../../components/association-top';
import BootstrapModal from '../../../components/modals/bootstrap-modal';
import SpinnerModal from '../../../components/modals/spinner-modal';
import TMAdminSideNav from '../../../components/tm-admin-sidenav';

import { getAssociationById, getAssociationByToken, getDocumentTypes, getAssociationDocumentsByTypeById, 
    parseAssociationTopFromAssociation, ensureAssociationAdminEmailValues } from '../../../helpers';
import { getAuthUsersMe } from '../../../helpers/get';
import { notifyDocumentUploaded } from '../../../helpers/mailers';
import { postUploadAssociationDocument } from '../../../helpers/post';
import { permitMasterAdminRole, permitAssociationAdminRole } from '../../../helpers/role_permissions';

class AAAddUpload extends Component {
    constructor(props) {
        super();
        this.state = {
            loading: true, ref: null, refId: null, unit_number: null, field: null, title: null, notifyMembers: null, file: null,
            modalHeader: null, modalBody: null, classNameValue: null, spinnerOn: false
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    async componentDidMount() {
        const { token } = this.props.match.params;
        const adminUser = await getAuthUsersMe();
        const permitMasterAdmin = await permitMasterAdminRole(adminUser.role.id);
        const permitAssociationAdmin = await permitAssociationAdminRole(adminUser.role.id);

        let association;
        if (permitMasterAdmin) {
            association = await getAssociationByToken(token);
            association = association[0];
            association = await getAssociationById(association._id);
        } else {
            association = await getAssociationById(adminUser.association_id);
        }
        const associationTop = await parseAssociationTopFromAssociation(association);

        this.setState({
            token, adminUser, association, associationTop, permitMasterAdmin, permitAssociationAdmin,
            loading: false, spinnerOn: true, unit_field_display: { display: 'none' }, unit_field_minlength: 0
        });
    }

    onClose() {
        console.log('modal closed...');
    }

    async onFormSubmit(e) {
        e.preventDefault() // Stop form submit

        let { document_type, unit_number, title, adminUser, notifyMembers, file, association } = this.state;
        notifyMembers = (notifyMembers === 'on') ? true : false;

        const formData = new FormData();
        formData.append('ref', 'association');
        formData.append('refId', association.id);
        formData.append('type', document_type);
        if (document_type === 'unit_documents') {
            if (unit_number === null) {
                this.setState({
                    spinnerOn: false,
                    modalHeader: `Sorry`,
                    modalBody: `
                    You must provide the unit value.
                    `,
                    classNameValue: 'warning'
                });
                document.getElementById("trigger-modal").click();
                return;
            }
            formData.append('unit_number', unit_number);
        }
        formData.append('title', title);
        formData.append('notifyMembers', notifyMembers);
        formData.append('files', file);
        this.refs.btn.setAttribute("disabled", "disabled");
        this.setState({ spinnerOn: true }); // this is required
        document.getElementById("trigger-spinner-modal").click(); // open spinner
        let result = await postUploadAssociationDocument(formData, association);
        if (result.status === 200) {
            // send email to association members
            if (notifyMembers === true) {
                let documents = await getAssociationDocumentsByTypeById(association.id, document_type);

                let data = {
                    document_path: `association_documents/${association.token}/${document_type}`,
                    association_id: association.id,
                    association_name: association.name,
                    association_token: association.token,
                    document_type,
                    document_title: title,
                    document_id: documents[0].id
                };
                data = await ensureAssociationAdminEmailValues(data, adminUser);

                notifyDocumentUploaded(data);
            }

            document.getElementById("upload_form").reset();
            this.setState({
                modalHeader: `Success!`,
                modalBody: `
                Your file was uploaded successfully.
                `,
                classNameValue: 'success'
            });
        } else {
            this.setState({
                modalHeader: `Sorry`,
                modalBody: `
                Your file did NOT upload.
                `,
                classNameValue: 'warning'
            });
            console.log('result', result);
        }
        document.getElementById("mySpinnerModal").click(); // close spinner
        document.getElementById("trigger-modal").click();
        this.setState({ spinnerOn: false });
        this.refs.btn.removeAttribute("disabled");
    }

    onChange(e) {
        // monitor document_type change for unit documents to show/hide the unit field
        if (e.target.name === 'document_type') {
            if (e.target.value === 'unit_documents') {
                this.setState({ unit_field_display: { display: 'block' }, unit_field_minlength: 1 })
            } else {
                this.setState({ unit_field_display: { display: 'none' }, unit_field_minlength: 0 })
            }
        }

        if (e.target.name === 'file') {
            this.setState({ file: e.target.files[0] })
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    renderDocumentTypes = (documentType, i) => {
        return (
            <option key={i} value={documentType.slug}>
                {documentType.fullName}
            </option>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <div className="App">
                    <h1>Loading...</h1>
                </div>
            )
        }

        const { token, permitMasterAdmin, permitAssociationAdmin, associationTop, spinnerOn, unit_field_display, unit_field_minlength } = this.state;
        if (!permitMasterAdmin && !permitAssociationAdmin) {
            return (
                <div className="App">
                    <h1>Sorry. You are not authorized to view this page.</h1>
                </div>
            )
        }

        var documentTypes = getDocumentTypes();
        documentTypes.sort((a, b) => (a.slug > b.slug) ? 1 : -1); // arrange alphabetically by slug

        return (
            <div className="App">
                <AssociationTop cbForAssociationTop={associationTop} />

                <section className="ftco-section ftc-no-pb">
                    <TMAdminSideNav token={token} />

                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-center">
                            <h3 className="mb-2 bread">Create New File</h3>
                        </div>
                    </div>

                    <section className="ftco-section bg-light">

                        <div className="container">

                            <div className="row">
                                <div className="properties">
                                    <div className="text p-3">
                                        <span className="status"></span>
                                        <div className="d-flex">
                                            <div className="one">
                                                <form onSubmit={this.onFormSubmit} id="upload_form">
                                                    <div className="form-group">
                                                        <label htmlFor="document_type">Document Type:</label><br />
                                                        <select name="document_type" className="custom-select" required onChange={this.onChange}>
                                                            <option value="">-- Please Choose --</option>
                                                            {documentTypes.map((documentType, i) => {
                                                                return this.renderDocumentTypes(documentType, i);
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group" style={unit_field_display}>
                                                        <label htmlFor="unit_number">Unit:</label>
                                                        <input name="unit_number" type="text" id="unit_number" minLength={unit_field_minlength} className="form-control" placeholder="Enter Unit" onChange={this.onChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="title">Title:</label>
                                                        <input name="title" type="text" className="form-control" placeholder="Enter title" id="title" required onChange={this.onChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="file">Upload:</label>
                                                        <input name="file" type="file" className="form-control" id="file" required onChange={this.onChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="notifyMembers">Notify Members: </label>
                                                        <input name="notifyMembers" type="checkbox" className="FormField__Checkbox" id="notifyMembers" onChange={this.onChange} />
                                                    </div>
                                                    <input type="hidden" id="trigger-modal" data-toggle="modal" data-target="#myModal" />
                                                    <button ref="btn" type="submit" className="FormField__Button mr-20">Submit</button>
                                                    <input type="hidden" id="trigger-spinner-modal" data-toggle="modal" data-target="#mySpinnerModal" />
                                                    {spinnerOn ? <SpinnerModal /> : null}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </section>
                <BootstrapModal onClose={this.onClose} cbForBootstrapModal={{ header: this.state.modalHeader, body: this.state.modalBody, classNameValue: this.state.classNameValue }} />

            </div>
        );
    };
}

export default AAAddUpload;
