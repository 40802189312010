/* this is a bootstrap modal */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/bootstrap.css';

import { getAssociationById } from '../../helpers';
import { getAuthUsersMe } from '../../helpers/get';
import { permitMasterAdminRole, permitAssociationAdminRole } from '../../helpers/role_permissions';

// deletion status modal not fully implemented

class ConfirmDeleteModal extends Component {
    constructor(props) {
        super();
        this.onClickConfirmDelete = this.onClickConfirmDelete.bind(this);
        this.state = { permitMasterAdmin: null, permitAssociationAdmin: null, association: null, loading: false, modalHeader: null, modalBody: null, classNameValue: null };
    }

    async componentDidMount() {
        const adminUser = await getAuthUsersMe();
        const permitMasterAdmin = await permitMasterAdminRole(adminUser.role.id);
        const permitAssociationAdmin = await permitAssociationAdminRole(adminUser.role.id);
        const association = await getAssociationById(adminUser.association_id);
        this.setState({ permitMasterAdmin, permitAssociationAdmin, association, loading: true });
    }

    async onClickConfirmDelete(ev = null) {
      this.props.onDeleteCallback({ spinnerOn: false, removeDocumentBlock: true });
      return;
    }

  render() {
        // Render nothing if the "show" prop is false
        if(!this.props.show) {
          return null;
        }
    

    let { header, body, classNameValue, customID, confirmAction, documentGroupSlug, id } = this.props.cbForBootstrapModal;
    // this.setState({document_file_id: id, documentGroupSlug: documentGroupSlug}); // this crashes the page
    this.state.document_file_id = id;
    this.state.documentGroupSlug = documentGroupSlug;

    // check user role authorized
    let { permitMasterAdmin, permitAssociationAdmin } = this.state;
    if (!permitMasterAdmin && !permitAssociationAdmin) {
        classNameValue = 'danger';
        header = 'Not Authorized';
        body = 'Your user role is not authorized to perform this action';
        confirmAction = null;
    }

    if (customID === undefined) customID = "myModal";
    let dialogClass = `modal-dialog modal-xl border border-${classNameValue}`;
    let headerClass = `modal-header bg-${classNameValue}`;

        // The modal "window"
        const modalStyle = {
          display: 'block'
        };
    
    return (
        <div id={customID} className="modal" style={modalStyle} role="dialog" tabIndex="-1">
          <div className={dialogClass}>
            <div className="modal-content">

              <div className={headerClass}>
                <h4 className="modal-title">{header}</h4>
                <button type="button" className="close" data-dismiss="modal" onClick={this.props.onCloseDeleteModal}>&times;</button>
              </div>

              <div className="modal-body">
                <p>{body}</p>
              </div>

              <div className="modal-footer">
                  {confirmAction === 'onClickConfirmDelete' && classNameValue === 'warning' ?
                        <div>
                            <button type="button" className="btn btn-warning" data-dismiss="modal" onClick={this.onClickConfirmDelete} >Confirm</button>
                        </div>
                      :
                      <div></div>
                  }
                <button type="button" className="btn btn-default" onClick={this.props.onCloseDeleteModal} data-dismiss="modal">Close</button>
              </div>

            </div>
          </div>
        </div>
    );
  }
}

ConfirmDeleteModal.propTypes = {
  onCloseDeleteModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
  // children: PropTypes.node
};

export default ConfirmDeleteModal;
