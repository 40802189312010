import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

class CustomMap extends Component {
    constructor(props) {
      super(props);
      let { lat, lng } = this.props.callbackFromAssociation;

      this.position = {
        center: {
            lat,
            lng
        },
        zoom: 12
      };
    }

renderMarkers(map, maps) {
    new maps.Marker({
        position: this.position.center,
        map,
        title: this.props.callbackFromAssociation.name
    });
}


render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBnTrsVu0TJjQ4fEK21Tfkjik_iIuLRibc'/* YOUR KEY HERE */ }}
          defaultCenter={this.position.center}
          defaultZoom={this.position.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
        >
        </GoogleMapReact>
      </div>
    );
  }
}

export default CustomMap;
