import React, { Component } from 'react';
import { Link } from "react-router-dom"
import { getAccountingDocuments, getAdministrationDocuments } from '../helpers';
import ModalDisplayDocument from '../components/modals/display-document';
import SiteFooterNav from '../components/site-footer-nav';

class SiteFooter extends Component {
  constructor(props) {
    super();
    this.state = { data: [], loading: true };
  }

  async componentDidMount() {
    const accountingDocuments = getAccountingDocuments();
    const administrationDocuments = getAdministrationDocuments();

    this.setState({ accountingDocuments, administrationDocuments, loading: false, isOpen: false, document_name: null, document_url: null, document_type: null });
  }

  toggleModal = (e = null) => {
    e.preventDefault();
    var values = {};
    if (e.currentTarget.getAttribute("value")) values = JSON.parse(e.currentTarget.getAttribute("value"));
    this.setState({
      isOpen: !this.state.isOpen,
      document_name: values.name || '',
      document_url: values.url || '',
      document_type: values.type || ''
    });
  }

  displayDocuments = (documents) => {
    console.log('documents', documents);

  }

  render() {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }

    var site_phone_number, site_address, site_email, site_intro, site_header_title = null;
    if (this.props.callbackVariablesFromApp.length) {
      site_phone_number = (this.props.callbackVariablesFromApp[0].value.length) ? this.props.callbackVariablesFromApp[0].value : 'No phone number found';
      site_address = (this.props.callbackVariablesFromApp[1].value.length) ? this.props.callbackVariablesFromApp[1].value : 'No address found';
      site_email = (this.props.callbackVariablesFromApp[2].value.length) ? this.props.callbackVariablesFromApp[2].value : 'No email found';
      site_intro = (this.props.callbackVariablesFromApp[3].value.length) ? this.props.callbackVariablesFromApp[3].value : 'No intro found';
      site_header_title = (this.props.callbackVariablesFromApp[4].value.length) ? this.props.callbackVariablesFromApp[4].value : 'No title found';
    } else {
      site_phone_number = 'No phone number found';
      site_address = 'No address found';
      site_email = 'No email found';
      site_intro = 'No intro found';
      site_header_title = 'No title found';
    }
    return (
      <footer className="ftco-footer ftco-bg-dark ftco-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">{site_header_title}</h2>
                <p>{site_intro}</p>
                <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                  <li className="ftco-animate"><Link to="#"><span className="icon-twitter"></span></Link></li>
                  <li className="ftco-animate"><Link to="#"><span className="icon-facebook"></span></Link></li>
                  <li className="ftco-animate"><Link to="#"><span className="icon-instagram"></span></Link></li>
                </ul>
              </div>
            </div>

            <SiteFooterNav callbackFromFooter={{ type: 'accounting' }} />
            <SiteFooterNav callbackFromFooter={{ type: 'administration' }} />

            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">Have a Questions?</h2>
                <div className="block-23 mb-3">
                  <ul>
                    <li><span className="icon icon-map-marker"></span><span className="text">{site_address}</span></li>
                    <li><Link to="#"><span className="icon icon-phone"></span><span className="text">{site_phone_number}</span></Link></li>
                    <li><Link to="#"><span className="icon icon-envelope"></span><span className="text">{site_email}</span></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">

              <p>
                {/*<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->*/}
                Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="icon-heart color-danger" aria-hidden="true"></i> by <Link to="https://colorlib.com" target="_blank">Colorlib</Link>
                {/*<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->*/}
              </p>
            </div>
          </div>
        </div>
        <ModalDisplayDocument show={this.state.isOpen}
          onClose={this.toggleModal}
        >
          <iframe width="100%" height="750" title={this.state.document_name} src={`https://docs.google.com/gview?url=${process.env.REACT_APP_API_ENDPOINT}${this.state.document_url}&embedded=true`} type={`application/${this.state.document_type}`} ></iframe>
        </ModalDisplayDocument>
      </footer>
    );
  }
}

export default SiteFooter;
