import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

class NavButton extends Component {
    constructor(props) {
        super();
        let { token, toPage, access, type, text } = props;
        this.state = { token, toPage, access, type, text };
    }

    async componentDidMount() {
        // this.setState({});
    }

    render() {
        const { token, toPage, access, type, text } = this.state;

        let path = (access === 'p') ? toPage: `/secure/${token}/${toPage}`;
        let btnType = (type.includes('view')) ? 'info': 'warning';
        btnType = (type.includes('go')) ? 'success': btnType;
        return (
            <NavLink to={path} className={`btn btn-${btnType}`} >
                {text}
            </NavLink>
        )
    }
}

export default NavButton;
