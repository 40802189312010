import React, { Component } from 'react';
import OwlCarousel2 from 'react-owl-carousel2';

class Home extends Component {
    constructor(props) {
        super();
        this.state = { data: {statusCode: 500}, loading: true };
    }

    async componentDidMount() {
        while (this.state.data.statusCode === 500) {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/hero_image`);
            const json = await response.json();
            this.setState({ data: json, loading: false });
        }
    }

    render () {
        if (this.state.loading) {
            return (
            <div className="App">
              <h1>Loading...</h1>
            </div>
          )
        }

        if (this.state.data.statusCode === 500) return null;
        var association_images = this.state.data;

        const options = {
            items: 1,
            autoplayTimeout: 4000, // 3 seconds
            smartSpeed: 400, // 3 seconds
            rewind: true,
            autoplay: true,
        };

        return (
            <div className="App">
            <section className="ftco-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                  	<div className="row">
                  		<div className="col-md-12">
                            <OwlCarousel2 options={options} >
                            {association_images.map((association, i) => {
                                var hero_url = (association.hero_image) ? `${process.env.REACT_APP_API_ENDPOINT}${association.hero_image.url}`: '/images/properties-1.jpg';
                                return (
                                   <div className="item" key={i}>
                                       <div className="properties-img">
                                           <img src={hero_url} alt={association.name} title={association.name} className="homepage_hero_image"/>
                                           <figcaption>
                                           <h3>{association.name}</h3>
                                           {association.city}, {association.state}
                                           </figcaption>
                                       </div>
                                   </div>
                                );
                            })}
                            </OwlCarousel2>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </section>
            </div>
        );
    }
}

export default Home;
