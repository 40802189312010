import React, { Component } from 'react';
import { NavLink } from "react-router-dom"
import { MDBInput } from "mdbreact";

import { getJwt, getAssociationsByZipcode } from '../helpers';

class Associations extends Component {
    constructor(props) {
        super();
        this.state = { data: [], search: "", loading: true };
    }

    async componentDidMount() {
        let jwt = getJwt();
        let parts = this.props.location.pathname.split('/');
        let zipcode = parts.pop() || parts.pop();  // handle potential trailing slash
        const json = await getAssociationsByZipcode(zipcode);
        this.setState({ data: json, jwt, zipcode, loading: false });
    }

    renderAssociation = (association, i) => {
      const { jwt, search } = this.state;
      var hero_url = (association.hero_image_url) ? `${process.env.REACT_APP_API_ENDPOINT}${association.hero_image_url}`: '/images/properties-1.jpg';

      if( search !== "" && association.name.toLowerCase().indexOf( search.toLowerCase() ) === -1 ){
          return null
      }

      let access = (jwt !== null) ? 'secure': 'public';
      let association_url = `/${access}/association/${association.token}`;

      return (
          <div className="row" key={i}>
              <div className="properties">
              <NavLink exact to={association_url} className="img img-2 d-flex justify-content-center align-items-center"
                  style={{backgroundImage: `url(${hero_url})`}}>
                  <div className="icon d-flex justify-content-center align-items-center">
                      <span className="icon-search2"></span>
                  </div>
              </NavLink>
              <div className="text p-3">
                  <span className="status sale"></span>
                  <div className="d-flex">
                      <div className="one">
                          <h3><NavLink exact to={association_url}>{association.name}</NavLink></h3>
                          <p>
                          <strong>Association ID: {association.token}</strong>
                          <br />
                          {association.address_line_1}
                          <br />
                          {association.city}, {association.state} {association.zipcode}
                          </p>
                          <hr />
                          <p>
                          {association.intro_text}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      );
    };

    onchange = e => {
      this.setState({ search: e.target.value });
    };

  render() {
      if (this.state.loading) {
          return (
          <div className="App">
            <h1>Loading...</h1>
          </div>
        )
      }

      if (this.state.data.length === 0) return null;
      var associations = this.state.data;
      associations.sort((a, b) => (a.name > b.name) ? 1 : -1); // arrange alphabetically by name

    return (
        <div className="App">

        <section className="ftco-section ftc-no-pb">
        <div className="container">
          <div className="row no-gutters slider-text align-items-center justify-content-center">
              <h3 className="mb-3 bread">Associations in {this.state.zipcode}</h3>
          </div>
        </div>

      <section className="ftco-section bg-light">

          <div className="container">
              <MDBInput
                hint="Search Association Name"
                icon="search"
                onChange={this.onchange}
              />
              <br />

        {associations.map((association, i) => {
            return this.renderAssociation(association, i);
        })}


          </div>
          </section>
          </section>

        </div>
    );
  };
}

export default Associations;
