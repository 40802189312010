import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom"
import './App.css';

import AuthComponent from '../components/authenticate';
import CustomTop from '../components/custom-top';
import CustomNav from '../components/custom-nav';
import SearchToken from '../components/search-token';
// import SubscribeNewsletter from '../components/subscribe-newsletter';
import SiteFooter from '../components/site-footer';

// public
import NotFound from '../pages/not_found';
import Home from '../pages/home';
import ForgotPassword from '../pages/forgot_password';
import ResetPassword from '../pages/reset-password';
import Login from '../pages/login';
import Logout from '../pages/logout';
import Register from '../pages/register';
import About from '../pages/about';
import Contact from '../pages/contact';
import Leadership from '../pages/leadership';
import Associations from '../pages/associations';
import TermsService from '../pages/terms-service';

// hybrid
import Association from '../pages/association';
import AssociationDocuments from '../pages/association_documents';

// secure
import UserPage from '../pages/user';

// secure section - Manage
import AssociationAdmin from '../pages/secure/index';
import AAManageInvoices from '../pages/secure/manage_invoices';
import AAManageMembers from '../pages/secure/manage_members';
import AAManageTickets from '../pages/secure/manage_tickets';
import AAManageUploads from '../pages/secure/manage_uploads';
import AAManageUnitDocuments from '../pages/secure/manage_unit_documents';

// secure section - View
import AAViewTicket from '../pages/secure/view/ticket';

// secure section - Add
import AAAddInvoice from '../pages/secure/add/invoice';
import AAAddTicket from '../pages/secure/add/ticket';
import AAAddUpload from '../pages/secure/add/upload';
import AAAddUnitDocument from '../pages/secure/add/unit_document';

// secure section - Edit
import AAEditTicket from '../pages/secure/edit/ticket';

// secure section - Delete
// import AADelete--- from '../pages/secure/delete/---';

import { getJwt } from '../helpers';

class App extends React.Component {
    constructor(props) {
        super();
        this.state = { data: [] };
    }

    async componentDidMount() {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/variables`);
        const json = await response.json();
        let isAuthenticated = getJwt();
        isAuthenticated = (isAuthenticated === null) ? false : true;
        this.setState({ data: json, isAuthenticated });
    }

    render() {
        if (this.state.data.length === 0) return null;
        var variables = this.state.data;
        var isAuthenticated = this.state.isAuthenticated;
        // console.log('isAuthenticated', isAuthenticated);

        return (
            <BrowserRouter>
                <CustomTop callbackVariablesFromApp={variables} />
                <CustomNav callbackVariablesFromApp={variables} />
                <SearchToken />
                <Switch>
                    {/* public pages */}
                    <Route path='/' exact component={Home} />
                    <Route path='/about' exact component={About} />
                    <Route path='/contact' exact component={Contact} />
                    <Route path='/leadership' exact component={Leadership} />
                    <Route path='/forgot-password' exact component={ForgotPassword} />
                    <Route path='/reset-password/:reset_token' exact component={ResetPassword} />
                    <Route path='/login' exact component={Login} />
                    <Route path='/logout' exact component={Logout} />
                    <Route path='/register' exact component={Register} />
                    <Route path='/terms_of_service' exact component={TermsService} />

                    <Route path='/:access/association/:token' exact component={Association} />
                    <Route path='/:access/association_documents/:token/:document_tag' exact component={AssociationDocuments} />
                    <Route path='/:access/associations/zipcode/:id' exact component={Associations} />

                    {/* private pages */}
                    <Route path='/:access/association_documents/:token/:document_tag/:auth_token' exact component={AssociationDocuments} />

                    {!isAuthenticated && <Route component={NotFound} /> 
                    // doesnt work for auth users hitting non auth pages... perhaps all auth pages should use 'secure' path prefix
                    }

                    {/* secure pages */}
                    <AuthComponent>
                        <Route path='/:access/association/:token' exact component={Association} />
                        <Route path='/:access/association_documents/:token/:document_tag' exact component={AssociationDocuments} />
                        <Route path='/user/:id' exact component={UserPage} />

                        {/* secure pages - Manage */}
                        <Route path='/secure/' exact component={AssociationAdmin} />
                        <Route path='/secure/:token/manage_invoices' exact component={AAManageInvoices} />
                        <Route path='/secure/:token/manage_members' exact component={AAManageMembers} />
                        <Route path='/secure/:token/manage_tickets' exact component={AAManageTickets} />
                        <Route path='/secure/:token/manage_unit_documents' exact component={AAManageUnitDocuments} />
                        <Route path='/secure/:token/manage_unit_documents/:user_id' exact component={AAManageUnitDocuments} />
                        <Route path='/secure/:token/manage_uploads' exact component={AAManageUploads} />

                        {/* secure pages - View */}
                        <Route path='/secure/:token/manage_tickets/view/ticket/:id' exact component={AAViewTicket} />

                        {/* secure pages - Add */}
                        <Route path='/secure/:token/manage_invoices/add/invoice' exact component={AAAddInvoice} />
                        <Route path='/secure/:token/manage_tickets/add/ticket' exact component={AAAddTicket} />
                        <Route path='/secure/:token/add/unit_document/:user_id' exact component={AAAddUnitDocument} />
                        <Route path='/secure/:token/add/upload' exact component={AAAddUpload} />

                        {/* secure pages - Edit */}
                        <Route path='/secure/:token/manage_tickets/edit/ticket/:id' exact component={AAEditTicket} />

                        {/* secure pages - Delete
                      <Route path='/secure/:token/manage_tickets/delete/---/:id' exact component={AADelete---} />
                      */}

                    </AuthComponent>


                </Switch>
                {/*<SubscribeNewsletter />*/}
                <SiteFooter callbackVariablesFromApp={variables} />
            </BrowserRouter>
        );
    }
}

export default App;
