import React, { Component } from 'react';
import { NavLink } from "react-router-dom"
import { FaKey } from 'react-icons/fa';
import '../assets/css/documents-nav.css';
import { getJwt, getDocumentTypes } from '../helpers';

class AssociationDocumentsNav extends Component {
    constructor(props) {
        super();
        this.state = { data: [], loading: true };
    }

    async componentDidMount() {
        let jwt = getJwt();
        const documentTypes = getDocumentTypes();
        const cbValues = await this.props.cbForAssociationDocumentsNav;

        const unitDocumentsCount = await this.props.associationUnitsDocumentsCount;

        for (var key in documentTypes) {
            documentTypes[key]['count'] = cbValues[documentTypes[key].slug];
            documentTypes[key]['token'] = cbValues.token;
        }

        this.setState({ jwt, token: cbValues.token, documentTypes, unitDocumentsCount, loading: false });
    }

    render () {
        if (this.state.loading) {
            return (
            <div className="App">
              <h1>Loading...</h1>
            </div>
          )
        }

        return (
              <div className="sidebar-box">
                <div className="categories documents">
                  <h3>Documents</h3>

                  {this.state.documentTypes.map((document, i) => {
                      return (
                          <div key={i}>
                          {document.count ?
                              <li>
                              {document.access === 'secure'? <FaKey /> : ''}
                              {this.state.jwt && document.access === 'secure' ?
                                  <NavLink to={`/${document.access}/association_documents/${document.token}/${document.slug}`} className={`${document.className}`} >
                                  {document.fullName} <span className="document-count">({document.count})</span></NavLink>
                              : ''
                              }
                              {!this.state.jwt && document.access === 'secure' ?
                                  <NavLink to={`/login?target=/${document.access}/association_documents/${document.token}/${document.slug}`} className={`${document.className}`} >
                                  {document.fullName} <span className="document-count">({document.count})</span></NavLink>
                              : ''
                              }
                              {document.access !== 'secure' ?
                                  <NavLink to={`/${document.access}/association_documents/${document.token}/${document.slug}`} className={`${document.className}`} >
                                  {document.fullName} <span className="document-count">({document.count})</span></NavLink>
                              : ''
                              }
                              </li>
                              :
                              <li>
                              {document.access === 'secure'? <FaKey /> : ''}
                              <span>{document.fullName} <span>({document.count})</span></span></li>
                          }
                          </div>
                      );
                  })}
                  
                </div>
              </div>
        );
    }
}

export default AssociationDocumentsNav;
