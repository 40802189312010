import { getJwt, sanitizeResponse } from '../helpers';
const method = 'PUT';
const jwt = getJwt();

export async function updateUserModified(body, user_id) {
    var data = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(body)
    };
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/${user_id}`, data);
    // console.log('response', response);
    return response;
}

export async function updateAssociationWithModifiedComponent(association) {
    var data = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(association)
    };
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/${association.id}`, data);
    return response;
}

export async function updateTicketById(ticket) {
    var data = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(ticket)
    };

    let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/tickets/${ticket.id}`, data);
    // console.log('response', response);
    let response2 = await response.json();
    let message = sanitizeResponse(response2);
    // console.log('message', message);
    return {message, status: response.status};
}
