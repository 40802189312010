import { getJwt } from '../helpers';
const method = 'GET';
const jwt = getJwt();

export async function getAuthUsersMe() {
    // Request User
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/me`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getRoleById(role_id) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users-permissions/roles/${role_id}`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getRoles() {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users-permissions/roles`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getTicketsByAssociationId(association_id) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/tickets?associations._id=${association_id}`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getUploadsByAssociationId(association_id) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/uploads?association_id=${association_id}`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getUserByUserId(user_id) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/${user_id}`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getUsersByAssociationId(association_id) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users?association_id=${association_id}&_limit=-1`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getTicketById(ticket_id) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/tickets/${ticket_id}`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getUnitDocumentsByUserId(user_id) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/unit-documents/user/${user_id}`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getAllUnitDocuments() {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/unit-documents`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getAssociationTeamUsers(association_id) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/team_users/${association_id}`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getAssociationAdministrator(association_id) {
    // used by public role at registration
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/administrator_user/${association_id}`);
    let result = await response.json();
    return result.result;
}

export async function getHOALeadership() {
    // used by public role at /leadership
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/hoa_leadership`);
    let result = await response.json();
    return result || [];
}
