import React, { Component } from 'react';
import { MDBInput } from "mdbreact";
import { NavLink } from "react-router-dom"

import "react-toggle/style.css" // for ES6 modules
import '../../assets/css/manage_members.css';

// import AssociationDocumentsNav from '../../components/association-documents-nav';
import AssociationTop from '../../components/association-top';
import ConfirmDeleteFileModal from '../../components/modals/confirm-delete-file-modal';
import ModalDisplayDocument from '../../components/modals/display-document';
import SpinnerModal from '../../components/modals/spinner-modal';
import TMAdminSideNav from '../../components/tm-admin-sidenav';
import TMMemberSideNav from '../../components/tm-member-sidenav';

import {
  getAssociationById, getAssociationByToken, getUnitDocumentTypes, parseDocumentsFromUnitDocuments, parseAssociationTopFromAssociation,
  getAssociationDocumentsCountById, getAssociationUnitsDocumentsCountById
} from '../../helpers';
import { getAuthUsersMe, getUserByUserId, getUnitDocumentsByUserId, getAllUnitDocuments } from '../../helpers/get';
import { permitMasterAdminRole, permitAssociationAdminRole, permitAssociationMemberRole } from '../../helpers/role_permissions';

class AAManageUnitDocuments extends Component {
  constructor(props) {
    super();
    var document = {
      id: null,
      title: null,
      // username: null,
      // confirmed: false,
      // blocked: true,
    };
    this.state = { search: "", loading: true, spinnerOn: false, isOpen: false, document, allRoles: [], result_response: {} };
    this.toggleModal = this.toggleModal.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  async componentDidMount() {
    const { token } = this.props.match.params;

    const loggedInUser = await getAuthUsersMe();

    // if admin user is checking other user documents then get the user record by the id in the url
    let unitDocumentsFull = [];
    let unitDocuments = [];
    let displayAsUnit = false;
    const { user_id } = this.props.match.params;
    if (user_id) {
      displayAsUnit = true;
      unitDocumentsFull = await getUnitDocumentsByUserId(user_id);
      unitDocuments = unitDocumentsFull.result;
    } else {
      unitDocumentsFull = await getAllUnitDocuments();
      unitDocuments = unitDocumentsFull;
    }

    let user = {};
    if (user_id && loggedInUser.id !== user_id) {
      user = await getUserByUserId(user_id);
    } else {
      user = loggedInUser;
    }
    const permitMasterAdmin = permitMasterAdminRole(loggedInUser.role.id);
    const permitAssociationAdmin = permitAssociationAdminRole(loggedInUser.role.id);
    const permitAssociationMember = permitAssociationMemberRole(loggedInUser.role.id);

    let association;
    if (permitMasterAdmin) {
      association = await getAssociationByToken(token);
      association = association[0];
      association = await getAssociationById(association._id);
    } else {
      association = await getAssociationById(loggedInUser.association_id);
    }
    const associationTop = parseAssociationTopFromAssociation(association);

    let associationDocumentsCount = await getAssociationDocumentsCountById(association._id);
    // let documents = await getAssociationDocumentsByTypeById(association._id, null);
    associationDocumentsCount['token'] = association.token;

    let documentTypes = getUnitDocumentTypes();
    unitDocuments = parseDocumentsFromUnitDocuments(unitDocuments);

    let associationUnitsDocumentsCount = await getAssociationUnitsDocumentsCountById(association._id);

    this.setState({
      token, user, displayAsUnit, associationTop, documentTypes, associationDocumentsCount, associationUnitsDocumentsCount,
      permitMasterAdmin, permitAssociationAdmin, permitAssociationMember, unitDocuments,
      loading: false, spinnerOn: true
    });
  }

  toggleModal = (ev = null) => {
    let values = {};
    if (ev.currentTarget.value) values = JSON.parse(ev.currentTarget.value);
    this.setState({
      isOpen: !this.state.isOpen,
      document_name: values.name || '',
      document_url: values.url || '',
      document_type: values.type || ''
    });
  }

  async onClickDelete(ev = null) {
    this.setState({ spinnerOn: true }); // this is required
    document.getElementById("trigger-spinner-modal").click(); // open spinner
    let values = {};
    if (ev.currentTarget.value) values = JSON.parse(ev.currentTarget.value);
    this.setState({
      modalHeader: `Are you sure?`,
      modalBody: `
            Please confirm you want to delete file "${values.name}".
            `,
      classNameValue: 'warning'
    });
    document.getElementById(`trigger-modal-${values.document_file_id}`).click();
  }

  renderDocuments = (document, i) => {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }
    var { search } = this.state;

    if (search !== "" && document.title.toLowerCase().indexOf(search.toLowerCase()) === -1) {
      return null
    }

    if (document === null) return (<div key={i}>null</div>);
    if (document.file === undefined) return (<div key={i}>undefined</div>);
    var clean_ext = document.file.ext.replace('.', '');
    var values = { url: document.file.url, type: clean_ext, name: document.file.name };
    // var deleteValues = { name: document.file.name, document_file_id: document.file.id, documentGroupSlug: document.documentGroupSlug };
    return (
      <div className="row" key={i}>
        <div className="properties">
          <div className="text p-3">
            <span className="status"></span>
            <div className="d-flex">
              <div className="one">
                <h4>{document.title}</h4>
                <br />
                Unit: <strong>{document.user.unitNumber}</strong>
                <br />
                Created: {document.createdAt}
                <br />
                File Name: {document.file.name}
                <br />
                Size: {document.file.size} KB
                <br />
                Type: {clean_ext}
                <br />
                <br />
                <button key={i} value={JSON.stringify(values)} onClick={this.toggleModal} className="btn btn-info mr-2" >
                  View
                </button>
                {/*
                            <button value={JSON.stringify(deleteValues)} onClick={this.onClickDelete} className="btn btn-danger mr-2" >
                                Delete
                            </button>
                            */}
                <input type="hidden" id={`trigger-modal-${document.file.id}`} data-toggle="modal" data-target={`#myModal-${document.file.id}`} />
                <ConfirmDeleteFileModal cbForBootstrapModal={{ header: this.state.modalHeader, body: this.state.modalBody, classNameValue: this.state.classNameValue, customID: `myModal-${document.file.id}`, confirmAction: "onClickConfirmDelete", documentGroupSlug: document.documentGroupSlug, id: document.file.id }} />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDocumentGroups = (documentGroup, i) => {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }
    var { search, unitDocuments } = this.state;

    if (unitDocuments[documentGroup.slug] === undefined ||
      (search !== "" && !unitDocuments[documentGroup.slug].length) ||
      (search !== "" && unitDocuments[documentGroup.slug][0].title.toLowerCase().indexOf(search.toLowerCase()) === -1)) {
      return null
    }

    return (
      <div key={i}>
        <div className="row no-gutters slider-text align-items-left">
          <h4 className="mb-3 bread">{documentGroup.fullName} ({unitDocuments[documentGroup.slug].length})</h4>
        </div>

        {unitDocuments[documentGroup.slug].map((document, i) => {
          document.documentGroupSlug = documentGroup.slug;
          return this.renderDocuments(document, i);
        })}
      </div>
    );
  };

  onchange = e => {
    this.setState({ search: e.target.value });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }

    const { token, permitMasterAdmin, permitAssociationAdmin, permitAssociationMember, associationTop,
      displayAsUnit, spinnerOn, documentTypes, user } = this.state;

    if (!permitMasterAdmin && !permitAssociationAdmin && !permitAssociationMember) {
      return (
        <div className="App">
          <h1>Sorry. You are not authorized to view this page.</h1>
        </div>
      )
    }

    documentTypes.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1); // arrange alphabetically by name
    let h3Title = (displayAsUnit) ? `Unit ${user.unitNumber} Documents` : `All Units Documents`;

    return (
      <div className="App">
        {spinnerOn ? <SpinnerModal /> : null}
        <input type="hidden" id="trigger-spinner-modal" data-toggle="modal" data-target="#mySpinnerModal" />

        <AssociationTop cbForAssociationTop={associationTop} />
        <section className="ftco-section ftc-no-pb">
          <TMAdminSideNav token={token} />
          <TMMemberSideNav />

          <div className="container">
            <div className="row no-gutters slider-text align-items-center justify-content-center">
              <h3 className="mb-3 bread">{h3Title}</h3>
            </div>
          </div>

          <section className="ftco-section bg-light">

            <div className="container">
              <MDBInput
                hint="Search Document Title"
                icon="search"
                onChange={this.onchange}
              />
              <br />
              {(permitMasterAdmin || permitAssociationAdmin) && displayAsUnit ?
                <NavLink to={`/secure/${token}/add/unit_document/${user.id}`} className="btn btn-success" role="button">Create New Unit {user.unitNumber} Document</NavLink>
                : null}
              <br />

              {documentTypes.map((documentGroup, i) => {
                return this.renderDocumentGroups(documentGroup, i);
              })}

              <ModalDisplayDocument show={this.state.isOpen}
                onClose={this.toggleModal}
              >
                <iframe width="100%" height="750" title={this.state.document_name} src={`https://docs.google.com/gview?url=${process.env.REACT_APP_API_ENDPOINT}${this.state.document_url}&embedded=true`} type={`application/${this.state.document_type}`} ></iframe>
              </ModalDisplayDocument>

              {/*
            <div className="col-lg-4 sidebar">
                <AssociationDocumentsNav cbForAssociationDocumentsNav={associationDocumentsCount} associationUnitsDocumentsCount={associationUnitsDocumentsCount} />
            </div>
        */}

            </div>
          </section>
        </section>

      </div>
    );
  };
}

export default AAManageUnitDocuments;
