import React, { Component } from 'react';
import { Link } from "react-router-dom"
import { getAccountingDocuments, getAdministrationDocuments } from '../helpers';
import ModalDisplayDocument from './modals/display-document';

class SiteFooterNav extends Component {
  constructor(props) {
    super();
    this.state = { data: [], loading: true };
  }

  async componentDidMount() {
    const { type } = this.props.callbackFromFooter;

    let documents = {};
    if (type === 'accounting') {
      documents = getAccountingDocuments();
    }
    if (type === 'administration') {
      documents = getAdministrationDocuments();
    }

    this.setState({ title: type.toUpperCase(), documents, loading: false, isOpen: false, document_name: null, document_url: null, document_type: null });
  }

  toggleModal = (e = null) => {
    e.preventDefault();
    var values = {};
    if (e.currentTarget.getAttribute("value")) values = JSON.parse(e.currentTarget.getAttribute("value"));
    this.setState({
      isOpen: !this.state.isOpen,
      document_name: values.name || '',
      document_url: values.url || '',
      document_type: values.type || ''
    });
  }

  displayDocuments = (documents) => {
    console.log('documents', documents);
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }

    const { title, documents } = this.state;

    return (
      <div className="col-md">
        <div className="ftco-footer-widget mb-4 ml-md-5">
          <h2 className="ftco-heading-2">{title}</h2>
          <ul className="list-unstyled">
            {documents.map((document, i) => {
              var clean_ext = document.slug.split('.')[1];
              // console.log('clean_ext', clean_ext);
              var values = { url: `/uploads/${document.slug}`, type: clean_ext, name: document.fullName };
              return (
                <div key={i}>
                  <li>
                    <Link to="/#" value={JSON.stringify(values)} onClick={this.toggleModal} className={`${document.className} py-2 d-block`} >{document.fullName}</Link>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
        <ModalDisplayDocument show={this.state.isOpen}
          onClose={this.toggleModal}
        >
          <iframe width="100%" height="750" title={this.state.document_name} src={`https://docs.google.com/gview?url=${process.env.REACT_APP_API_ENDPOINT}${this.state.document_url}&embedded=true`} type={`application/${this.state.document_type}`} ></iframe>
        </ModalDisplayDocument>
      </div>
    );
  }
}

export default SiteFooterNav;
