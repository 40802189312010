import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import '../../assets/css/bootstrap.css';

// this is a bootstrap modal

import spinnerPath from '../../assets/images/spinner.gif' // relative path to image
const Spinner = () => (
  <img src={spinnerPath} alt="spinner" height="50" width="50" className="col-lg-1 col-centered" style={{flex: 0, maxWidth: "inherit", height: "auto"}} />
)

class SpinnerModal extends Component {
  render() {
      let dialogClass = `modal-dialog modal-sm`;

    return (
        <div id="mySpinnerModal" className="modal fade" role="dialog" tabIndex="-1" >
            <div className={dialogClass}>
                <div className="modal-content">

                <Spinner />

                </div>
            </div>
        </div>
    );
  }
}

export default SpinnerModal;
