import React, { Component } from 'react';
import { doLogout } from '../helpers/auth';

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined
        };
    }

    async componentDidMount() {
        if (this.props.location.pathname === '/logout') {
            doLogout();
        }
    }

    render() {
        return (
            <div>
                Logging out...
            </div>
        );
    }

}

export default Logout;
