import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

import '../assets/css/general.css';

class CustomNav extends Component {

    triggerSearch() {
        var el = document.getElementById('search-token');
        if (el.classList.contains('hide')) {
            el.classList.remove("hide");
            document.getElementById("search-token-input").focus();
        } else {
            el.classList.add("hide");
        }
    }

    render () {

        var site_header_title  = null;
        if (this.props.callbackVariablesFromApp.length) {
            site_header_title = (this.props.callbackVariablesFromApp[4].value.length) ? this.props.callbackVariablesFromApp[4].value: 'No title found';
        } else {
            site_header_title = 'No title found';
        }
        return (
            <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
                <div className="container">
                  <NavLink className="navbar-brand" to="/">{process.env.REACT_APP_DOC_TITLE_PREFIX}{site_header_title}</NavLink>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="oi oi-menu"></span> Menu
                  </button>

                  <div className="collapse navbar-collapse" id="ftco-nav">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item"><NavLink exact to="/" className="nav-link" activeClassName='active'>Home</NavLink></li>
                      <li className="nav-item"><NavLink to="/about" className="nav-link" activeClassName='active'>About</NavLink></li>
                      <li className="nav-item"><NavLink to="/contact" className="nav-link" activeClassName='active'>Contact</NavLink></li>
                      <li className="nav-item"><NavLink to="/leadership" className="nav-link" activeClassName='active'>Organization</NavLink></li>
                      <li className="nav-item"><span className="nav-link"><span className="icon-search link" onClick={this.triggerSearch}></span></span></li>
                    </ul>
                </div>
                </div>
            </nav>
        );
    }
}

export default CustomNav;
