import React, { Component } from 'react';
import OwlCarousel2 from 'react-owl-carousel2';

import CustomMap from '../components/custom-map';
import AssociationTop from '../components/association-top';
import AssociationDocumentsNav from '../components/association-documents-nav';
import TMAdminSideNav from '../components/tm-admin-sidenav';
import TMMemberSideNav from '../components/tm-member-sidenav';
import avatarDefault from '../assets/images/avatar-default.png';

import { getJwt, ensureSecureAuthPath, ensurePublicUnAuthPath, requireMatchingUserToken,
    getAssociationDocumentsCountById, getAssociationUnitsDocumentsCountById, parseAssociationTopFromAssociation } from '../helpers';

class Association extends Component {
    constructor(props) {
        super();
        this.state = { data: [], loading: true };
    }

    async componentDidMount() {
        var jwt = getJwt();
        const { access, token } = this.props.match.params;

        ensureSecureAuthPath(this.props, access, jwt, `association/${token}`);
        ensurePublicUnAuthPath(this.props, access, jwt, `association/${token}`);
        const association = await requireMatchingUserToken(jwt, access, token, `association/`);

        let associationTop = await parseAssociationTopFromAssociation(association);
        let associationDocumentsCount = await getAssociationDocumentsCountById(association._id);
        associationDocumentsCount['token'] = token;

        let associationUnitsDocumentsCount = await getAssociationUnitsDocumentsCountById(association._id);

        this.setState({ association, associationDocumentsCount, associationUnitsDocumentsCount, associationTop, token, loading: false, jwt });
    }

    render() {
        if (this.state.loading) {
            return (
            <div className="App">
              <h1>Loading...</h1>
            </div>
          )
        }

        const { association, associationDocumentsCount, associationUnitsDocumentsCount, associationTop, token } = this.state;
        let directors = association.directors || [];

        const options = {
            items: 1,
            autoplayTimeout: 3000, // 3 seconds
            smartSpeed: 300, // 3 seconds
            rewind: true,
            autoplay: true,
        };

        const associationCallback = {
            name: association.name,
            lat: association.physical_address.latitude,
            lng: association.physical_address.longitude
        };

        return (
            <div className="App">
                <AssociationTop cbForAssociationTop={associationTop} />

            <section className="ftco-section ftc-no-pb">
            {this.state.jwt ? <TMAdminSideNav token={token} />: null }
            {this.state.jwt ? <TMMemberSideNav />: null }

            <section className="ftco-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                  	<div className="row">
                  		<div className="col-md-12">
                            <OwlCarousel2 options={options} >
                            {association.images.map((image, i) => {
                                return (
                                   <div className="item" key={i}>
                                           <div className="properties-img" style={{backgroundImage: `url(${process.env.REACT_APP_API_ENDPOINT}${image.url})`}} ></div>
                                   </div>
                                );
                            })}
                            </OwlCarousel2>
                  		</div>
                  		<div className="col-md-12 Properties-single mt-4 mb-5">
                        <h2>{association.name}</h2>
                        <p>{association.about}</p>
                  			<div className="rate mb-4">
                                <h5>Association Physical Location</h5>
                                <CustomMap callbackFromAssociation={associationCallback}/>
                                {association.physical_address ?
                                    <span className="loc"><i className="icon-map"></i>{association.physical_address.address_line_1} {association.physical_address.city}, {association.physical_address.state} {association.physical_address.zipcode}</span>
                                    :
                                    <h6>No physical address provided</h6>
                                }
                                <br />
                                <br />
                                <h5>Association Mailing Location</h5>
                                {association.mailing_address ?
                      				<span className="loc"><i className="icon-map"></i>{association.mailing_address.address_line_1} {association.mailing_address.city}, {association.mailing_address.state} {association.mailing_address.zipcode}</span>
                                    :
                                    <h6>No Mailing address provided</h6>
                                }
                                <br />
                                <br />
                                <h5>Contact Information</h5>
                                Contact name: {association.est_name}
                                <br />
                                Business Email: <a href={`mailto:${association.est_email}`}>{association.est_email}</a>
                                <br />
                                Office Phone: {association.officePhone}
                                <br />
                                Fax Number: {association.office_fax}
                                <br />
                                {association.est_address ?
                                    <span className="loc">{association.est_address.address_line_1} {association.est_address.city}, {association.est_address.state} {association.est_address.zipcode}</span>
                                    :
                                    <h6>No Contact address provided</h6>
                                }
    						</div>
                  		</div>

                        {this.state.jwt ?
                        <div className="col-md-12 properties-single mb-5 mt-4">
                            <h3 className="mb-4">Association Directors</h3>
                            <div className="block-16" style={{width: "100%"}}>
                            {directors.map((director, i) => {
                                var director_photo_url = (director.photo && director.photo.url) ? `${process.env.REACT_APP_API_ENDPOINT}${director.photo.url}`: avatarDefault;
                                return (
                                   <figure style={{width: "30%", float: "left", position: "relative"}} key={i}>
             		                 <img src={director_photo_url} alt={director.full_name} className="img-fluid" height="125px" width="125px" />
                                     <figcaption>
                                     {director.full_name} <br />
                                     {director.title} <br />
                                     {director.email} <br />
                                     </figcaption>
               		               </figure>
                                );
                            })}
                            </div>
                        </div>
                        :
                        <div className="col-md-12 properties-single mb-5 mt-4">
                            <h6>Please log in to see Board Members</h6>
                        </div>
                        }

                        {/*
                  		<div className="col-md-12 properties-single mb-5 mt-4">
                  			<h3 className="mb-4">Take A Tour</h3>
                  			<div className="block-16">
        		              <figure>
        		                <img src="images/properties-6.jpg" alt="placeholder" className="img-fluid" />
        		                <a href="https://vimeo.com/45830194" className="play-button popup-vimeo"><span className="icon-play"></span></a>
        		              </figure>
        		            </div>
                  		</div>

                  		<div className="col-md-12 properties-single mb-5 mt-4">
                  			<h4 className="mb-4">Review &amp; Ratings</h4>
                  			<div className="row">
                  				<div className="col-md-6">
                  					<form method="post" className="star-rating">
        										  <div className="form-check">
        												<input type="checkbox" className="form-check-input" id="exampleCheck1" />
        												<label className="form-check-label" htmlFor="exampleCheck1">
        													<p className="rate"><span><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i> 100 Ratings</span></p>
        												</label>
        										  </div>
        										  <div className="form-check">
        									      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
        									      <label className="form-check-label" htmlFor="exampleCheck1">
        									    	   <p className="rate"><span><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star-o"></i> 30 Ratings</span></p>
        									      </label>
        										  </div>
        										  <div className="form-check">
        									      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
        									      <label className="form-check-label" htmlFor="exampleCheck1">
        									      	<p className="rate"><span><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star-o"></i><i className="icon-star-o"></i> 5 Ratings</span></p>
        									     </label>
        										  </div>
        										  <div className="form-check">
        										    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
        									      <label className="form-check-label" htmlFor="exampleCheck1">
        									      	<p className="rate"><span><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star-o"></i><i className="icon-star-o"></i><i className="icon-star-o"></i> 0 Ratings</span></p>
        									      </label>
        										  </div>
        										  <div className="form-check">
        									      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
        									      <label className="form-check-label" htmlFor="exampleCheck1">
        									      	<p className="rate"><span><i className="icon-star"></i><i className="icon-star-o"></i><i className="icon-star-o"></i><i className="icon-star-o"></i><i className="icon-star-o"></i> 0 Ratings</span></p>
        										    </label>
        										  </div>
        										</form>
                  				</div>
                  			</div>
                  		</div>
                  		<div className="col-md-12 properties-single mb-5 mt-5">
                  			<h4 className="mb-4">Related Properties</h4>
                  			<div className="row">
                  				<div className="col-md-6">
        				    				<div className="properties">
        				    					<a href="property-single.html" className="img img-2 d-flex justify-content-center align-items-center" style={{backgroundImage: `url(http://js.hoaoa.org:1337/${image.url})`}}>
        				    						<div className="icon d-flex justify-content-center align-items-center">
        				    							<span className="icon-search2"></span>
        				    						</div>
        				    					</a>
        				    					<div className="text p-3">
        				    						<span className="status sale">Sale</span>
        				    						<div className="d-flex">
        				    							<div className="one">
        						    						<h3><a href="property-single.html">North Parchmore Street</a></h3>
        						    						<p>Apartment</p>
        					    						</div>
        					    						<div className="two">
        					    							<span className="price">$20,000</span>
        				    							</div>
        				    						</div>
        				    						<p>Far far away, behind the word mountains, far from the countries</p>
        				    						<hr />
        				    						<p className="bottom-area d-flex">
        				    							<span><i className="flaticon-selection"></i> 250sqft</span>
        				    							<span className="ml-auto"><i className="flaticon-bathtub"></i> 3</span>
        				    							<span><i className="flaticon-bed"></i> 4</span>
        				    						</p>
        				    					</div>
        				    				</div>
        				    			</div>
        				    			<div className="col-md-6">
        				    				<div className="properties">
        				    					<a href="property-single.html" className="img img-2 d-flex justify-content-center align-items-center" style={{backgroundImage: `url(http://js.hoaoa.org:1337/${image.url})`}}>
        				    						<div className="icon d-flex justify-content-center align-items-center">
        				    							<span className="icon-search2"></span>
        				    						</div>
        				    					</a>
        				    					<div className="text p-3">
        				    						<span className="status sale">Sale</span>
        				    						<div className="d-flex">
        				    							<div className="one">
        						    						<h3><a href="property-single.html">North Parchmore Street</a></h3>
        						    						<p>Apartment</p>
        					    						</div>
        					    						<div className="two">
        					    							<span className="price">$20,000</span>
        				    							</div>
        				    						</div>
        				    						<p>Far far away, behind the word mountains, far from the countries</p>
        				    						<hr />
        				    						<p className="bottom-area d-flex">
        				    							<span><i className="flaticon-selection"></i> 250sqft</span>
        				    							<span className="ml-auto"><i className="flaticon-bathtub"></i> 3</span>
        				    							<span><i className="flaticon-bed"></i> 4</span>
        				    						</p>
        				    					</div>
        				    				</div>
        				    			</div>
                  			</div>
                  		</div>
*/}
                  	</div>
                  </div> {/*<!-- .col-md-8 -->*/}

                  <div className="col-lg-4 sidebar">
                      <AssociationDocumentsNav cbForAssociationDocumentsNav={associationDocumentsCount} associationUnitsDocumentsCount={associationUnitsDocumentsCount} />

                    {/*
                    <div className="sidebar-box">
                      <h3>Tag Cloud</h3>
                      <div className="tagcloud">
                        <a href="1" className="tag-cloud-link">dish</a>
                        <a href="2" className="tag-cloud-link">menu</a>
                        <a href="3" className="tag-cloud-link">food</a>
                        <a href="4" className="tag-cloud-link">sweet</a>
                        <a href="5" className="tag-cloud-link">tasty</a>
                        <a href="6" className="tag-cloud-link">delicious</a>
                        <a href="7" className="tag-cloud-link">desserts</a>
                        <a href="8" className="tag-cloud-link">drinks</a>
                      </div>
                    </div>

                    <div className="sidebar-box">
                      <h3>Paragraph</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus itaque, autem necessitatibus voluptate quod mollitia delectus aut, sunt placeat nam vero culpa sapiente consectetur similique, inventore eos fugit cupiditate numquam!</p>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </section>


            </section>
            </div>
        );
    };
}

export default Association;
