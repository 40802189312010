import React, { Component } from 'react';
import { Link } from "react-router-dom"
import { getJwt, setAuthUserDetails, getAuthUserDetails } from '../helpers';

class CustomTop extends Component {
    constructor(props) {
        super();
        this.state = { user: [], loading: true };
    }

    async componentDidMount() {
        await setAuthUserDetails();
        const jwt = getJwt();
        if (jwt != null) {
            const user = await getAuthUserDetails();
            this.setState({ user, loading: false });
        } else {
            this.setState({ loading: false });
        }
    }

    render () {
        const jwt = getJwt();
        if (jwt != null && this.state.loading) {
            return (
            <div className="App">
              <h1>Loading...</h1>
            </div>
          )
        }
        var site_phone_number  = null;
        if (this.props.callbackVariablesFromApp.length) {
            site_phone_number = (this.props.callbackVariablesFromApp[0].value.length) ? this.props.callbackVariablesFromApp[0].value: 'No phone number found';
        } else {
            site_phone_number = 'No phone number found';
        }
        const { user } = this.state;

        return (
            <div className="top">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col">
                            <p className="social d-flex">
                                <Link to="#"><span className="icon-facebook"></span></Link>
                                <Link to="#"><span className="icon-twitter"></span></Link>
                                <Link to="#"><span className="icon-google"></span></Link>
                                <Link to="#"><span className="icon-pinterest"></span></Link>
                                <Link to="#"><span className="icon-phone"></span> {site_phone_number}</Link>
                            </p>
                        </div>
                        <div className="col d-flex justify-content-end">
                        {jwt !== null && user.role !== 'Authenticated' && user.role !== 'Master Admin' ?
                            <span>Hello <Link to={`/user/${user.id}`}>{user.username} ({user.role})</Link> </span> : null 
                        }
                        {jwt !== null && user.role === 'Master Admin' ?
                            <span>Hello <Link to={`/public/associations/zipcode/33040`}>{user.username} ({user.role})</Link> </span> : null 
                        }
                        {jwt !== null && user.role === 'Authenticated' ?
                            <span className="text-danger">ROLE NOT SET: Notify your Administrator </span> : null
                        }
                        {jwt === null ?
                            <span><Link to="/login">Please Log in</Link> or <Link to="/register">Please Register</Link></span>
                            :  
                            <span> | <a href="/logout">Log Out <span className="icon-exit_to_app"></span></a></span>
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomTop;
