import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

// import "react-toggle/style.css" // for ES6 modules
import '../../../assets/css/form.css';

import AssociationTop from '../../../components/association-top';
import NavButton from '../../../components/nav-button';
import TMAdminSideNav from '../../../components/tm-admin-sidenav';
import TMMemberSideNav from '../../../components/tm-member-sidenav';

import { getAssociationById, parseAssociationTopFromAssociation, parseDateTime, getTicketTypes, parseValueBySlug } from '../../../helpers';
import { getAuthUsersMe, getTicketById } from '../../../helpers/get';
import { permitMasterAdminRole, permitAssociationAdminRole, permitAssociationMemberRole, parseRoleNameById } from '../../../helpers/role_permissions';

class AAViewTicket extends Component {
  constructor(props) {
    super();
    this.state = { loading: true };
  }

  async componentDidMount() {
    const { token, id } = this.props.match.params;

    const loggedInUser = await getAuthUsersMe();
    const permitMasterAdmin = await permitMasterAdminRole(loggedInUser.role.id);
    const permitAssociationAdmin = await permitAssociationAdminRole(loggedInUser.role.id);
    const permitAssociationMember = await permitAssociationMemberRole(loggedInUser.role.id);
    const association = await getAssociationById(loggedInUser.association_id);
    const associationTop = await parseAssociationTopFromAssociation(association);
    const ticket = await getTicketById(id);
    if (ticket.assigned_to === undefined) ticket.assigned_to = { id: null }; // set default in case not yet assigned

    const ticketTypes = getTicketTypes();
    ticket.type = parseValueBySlug(ticketTypes, ticket.type, 'fullName');

    this.setState({ token, associationTop, permitMasterAdmin, permitAssociationAdmin, permitAssociationMember, ticket, ticketTypes, loading: false });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }

    const { token, associationTop, ticket, permitMasterAdmin, permitAssociationAdmin, permitAssociationMember } = this.state;

    if (!permitMasterAdmin && !permitAssociationAdmin && !permitAssociationMember) {
      return (
        <div className="App">
          <h1>Sorry. You are not authorized to view this page.</h1>
        </div>
      )
    }

    return (
      <div className="App">
        <AssociationTop cbForAssociationTop={associationTop} />

        <section className="ftco-section ftc-no-pb">
          <TMAdminSideNav token={token} />
          <TMMemberSideNav />

          <section className="ftco-section bg-light">
            <div className="container">
              <div className="row">
                <div className="properties">
                  <span className="status"></span>
                  <div className="card" style={{ width: '69rem' }}>
                    <div className="card-body">
                      <h3 className="card-title">{ticket.title}</h3>
                      <p className="card-text">Assigned To: {ticket.assigned_to.firstName} {ticket.assigned_to.lastName} ({parseRoleNameById(ticket.assigned_to.role)})</p>
                      <p className="card-text">Type: {ticket.type}</p>
                      <p className="card-text">Status: {ticket.status}</p>
                      <p className="card-text">Description: {ticket.description}</p>
                      <p className="card-text">Created: {parseDateTime(ticket.createdAt)}</p>
                      <p className="card-text">Updated: {parseDateTime(ticket.updatedAt)}</p>
                      <p className="card-text">Created By: {ticket.created_by_user.username}</p>

                      <NavButton token={token} access="s" toPage={`manage_tickets`} type={`viewList`} text={`View List`} />
                      {permitAssociationAdmin ?
                        <NavLink to={`/secure/${token}/manage_tickets/edit/ticket/${ticket._id}`} className="btn btn-warning m-2" >
                          Edit
                                    </NavLink>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    );
  };
}

export default AAViewTicket;
