import React, { Component } from 'react';

// import "react-toggle/style.css" // for ES6 modules
import '../../../assets/css/form.css';

import AssociationTop from '../../../components/association-top';
import BootstrapModal from '../../../components/modals/bootstrap-modal';
import SpinnerModal from '../../../components/modals/spinner-modal';
import TMAdminSideNav from '../../../components/tm-admin-sidenav';

import { ensureAssociationAdminEmailValues, getAssociationById, getAssociationByToken, parseAssociationTopFromAssociation } from '../../../helpers';
import { getAuthUsersMe } from '../../../helpers/get';
import { notifyNewInvoiceEmail } from '../../../helpers/mailers';
import { permitAccountantRole, permitMasterAdminRole } from '../../../helpers/role_permissions';

class AAAddInvoice extends Component {
    constructor(props) {
        super();
        this.state = {
            loading: true, type: null, title: null, description: null,
            modalHeader: null, modalBody: null, classNameValue: null, spinnerOn: false
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    async componentDidMount() {
        const { token } = this.props.match.params;
        const loggedInUser = await getAuthUsersMe();
        const permitAccountant = permitAccountantRole(loggedInUser.role.id);
        const permitMasterAdmin = permitMasterAdminRole(loggedInUser.role.id);

        let association;
        if (permitMasterAdmin) {
            association = await getAssociationByToken(token);
            association = association[0];
            association = await getAssociationById(association._id);
        } else {
            association = await getAssociationById(loggedInUser.association_id);
        }
        const associationTop = parseAssociationTopFromAssociation(association);

        this.setState({ token, loggedInUser, associationTop, adminuser: association.adminuser, permitMasterAdmin, permitAccountant, loading: false, spinnerOn: true });
    }

    onClose() {
        console.log('modal closed...');
    }

    async onFormSubmit(e) {
        e.preventDefault() // Stop form submit
        // this.refs.btn.setAttribute("disabled", "disabled");
        this.setState({ spinnerOn: true }); // this is required
        document.getElementById("trigger-spinner-modal").click(); // open spinner

        let { vendor_name, invoice_number, invoice_date, description, invoice_amount, adminuser, loggedInUser, associationTop } = this.state;
        // var body = { type, title, description, associations: loggedInUser.association_id, created_by_user: loggedInUser.id };
        // let result = await postCreateInvoice(body);
        // if (result.status === 200) {
            // send email to association admin
            let data = {
                vendor_name,
                invoice_number,
                invoice_date,
                description,
                invoice_amount,
                association_id: loggedInUser.association_id,
                association_name: associationTop.name,
            };
            data = await ensureAssociationAdminEmailValues(data, adminuser);
            let notifyResponse = await notifyNewInvoiceEmail(data);
                    
            if (notifyResponse.status === 200) {
                
                document.getElementById("invoice_form").reset();
                this.setState({
                    modalHeader: `Success!`,
                    modalBody: `
                    Your invoice was sent successfully.
                    `,
                    classNameValue: 'success'
                });
            }

        // } else {
        //     this.setState({
        //         modalHeader: `Sorry`,
        //         modalBody: `
        //         Your invoice was NOT created.
        //         `,
        //         classNameValue: 'warning'
        //     });
        //     console.log('result', result);
        // }
        document.getElementById("mySpinnerModal").click(); // close spinner
        document.getElementById("trigger-modal").click();
        this.setState({ spinnerOn: false });
        // this.refs.btn.removeAttribute("disabled");
    }

    async onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="App">
                    <h1>Loading...</h1>
                </div>
            )
        }

        const { token, associationTop, spinnerOn, permitMasterAdmin, permitAccountant } = this.state;

        if (!permitMasterAdmin && !permitAccountant) {
            return (
                <div className="App">
                    <h1>Sorry. You are not authorized to view this page.</h1>
                </div>
            )
        }

        return (
            <div className="App">
                <AssociationTop cbForAssociationTop={associationTop} />

                <section className="ftco-section ftc-no-pb">
                    <TMAdminSideNav token={token} />

                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-center">
                            <h3 className="mb-2 bread">Create New Invoice</h3>
                        </div>
                    </div>

                    <section className="ftco-section bg-light">

                        <div className="container">

                            <div className="row">
                                <div className="properties">
                                    <div className="text p-3">
                                        <span className="status"></span>
                                        <div className="d-flex">
                                            <div className="one">
                                                <form onSubmit={this.onFormSubmit} id="invoice_form">
                                                <div className="form-group">
                                                        <label htmlFor="vendor_name">Vendor Name:</label>
                                                        <input name="vendor_name" type="text" className="form-control" placeholder="Enter vendor name" id="vendor_name" required minLength="1" onChange={this.onChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="invoice_number">Invoice Number:</label>
                                                        <input name="invoice_number" type="text" className="form-control" placeholder="Enter invoice number" id="invoice_number" required minLength="1" onChange={this.onChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="invoice_date">Invoice Date:</label>
                                                        <input name="invoice_date" type="date" className="form-control" placeholder="Enter invoice date" id="invoice_date" required minLength="10" onChange={this.onChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="description">Description:</label>
                                                        <textarea name="description" type="description" className="form-control" placeholder="Enter description" id="description" required minLength="2" onChange={this.onChange}></textarea>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="invoice_amount">Invoice Amount:</label>
                                                        <input name="invoice_amount" type="number" step=".01" className="form-control" placeholder="Enter invoice amount" id="invoice_amount" required minLength="10" onChange={this.onChange} />
                                                    </div>
                                                    <button ref="btn" type="submit" className="FormField__Button mr-20">Submit</button>
                                                    <input type="hidden" id="trigger-modal" data-toggle="modal" data-target="#myModal" />
                                                    <input type="hidden" id="trigger-spinner-modal" data-toggle="modal" data-target="#mySpinnerModal" />
                                                    {spinnerOn ? <SpinnerModal /> : null}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </section>
                <BootstrapModal onClose={this.onClose} cbForBootstrapModal={{ header: this.state.modalHeader, body: this.state.modalBody, classNameValue: this.state.classNameValue }} />

            </div>
        );
    };
}

export default AAAddInvoice;
