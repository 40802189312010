import React, { Component } from 'react';

// import "react-toggle/style.css" // for ES6 modules
import '../../../assets/css/form.css';

import AssociationTop from '../../../components/association-top';
import BootstrapModal from '../../../components/modals/bootstrap-modal';
import SpinnerModal from '../../../components/modals/spinner-modal';
import TMAdminSideNav from '../../../components/tm-admin-sidenav';
import TMMemberSideNav from '../../../components/tm-member-sidenav';

import { ensureAssociationAdminEmailValues, getAssociationById, getAssociationByToken, getTicketTypes, parseAssociationTopFromAssociation } from '../../../helpers';
import { getAuthUsersMe } from '../../../helpers/get';
import { notifyNewTicketEmail } from '../../../helpers/mailers';
import { postCreateTicket } from '../../../helpers/post';
import { permitMasterAdminRole, permitAssociationAdminRole, permitAssociationMemberRole } from '../../../helpers/role_permissions';

class AAAddTicket extends Component {
    constructor(props) {
        super();
        this.state = {
            loading: true, type: null, title: null, description: null,
            modalHeader: null, modalBody: null, classNameValue: null, spinnerOn: false
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    async componentDidMount() {
        const { token } = this.props.match.params;
        const loggedInUser = await getAuthUsersMe();
        const permitMasterAdmin = await permitMasterAdminRole(loggedInUser.role.id);
        const permitAssociationAdmin = await permitAssociationAdminRole(loggedInUser.role.id);
        const permitAssociationMember = await permitAssociationMemberRole(loggedInUser.role.id);

        let association;
        if (permitMasterAdmin) {
            association = await getAssociationByToken(token);
            association = association[0];
            association = await getAssociationById(association._id);
        } else {
            association = await getAssociationById(loggedInUser.association_id);
        }
        const associationTop = await parseAssociationTopFromAssociation(association);

        this.setState({ token, loggedInUser, associationTop, adminuser: association.adminuser, permitMasterAdmin, permitAssociationAdmin, permitAssociationMember, loading: false, spinnerOn: true });
    }

    onClose() {
        console.log('modal closed...');
    }

    async onFormSubmit(e) {
        e.preventDefault() // Stop form submit
        // this.refs.btn.setAttribute("disabled", "disabled");
        this.setState({ spinnerOn: true }); // this is required
        document.getElementById("trigger-spinner-modal").click(); // open spinner

        let { type, title, description, loggedInUser, adminuser, associationTop } = this.state;
        var body = { type, title, description, associations: loggedInUser.association_id, created_by_user: loggedInUser.id };
        let result = await postCreateTicket(body);
        if (result.status === 200) {
            // send email to association admin
            let data = {
                uid: loggedInUser.id,
                association_id: loggedInUser.association_id,
                association_name: associationTop.name,
                email: loggedInUser.email,
                ticket_type: type,
                ticket_id: result.message.id,
            };
            data = await ensureAssociationAdminEmailValues(data, adminuser);
            notifyNewTicketEmail(data);

            document.getElementById("ticket_form").reset();
            this.setState({
                modalHeader: `Success!`,
                modalBody: `
                Your ticket was created successfully.
                `,
                classNameValue: 'success'
            });
        } else {
            this.setState({
                modalHeader: `Sorry`,
                modalBody: `
                Your ticket was NOT created.
                `,
                classNameValue: 'warning'
            });
            console.log('result', result);
        }
        document.getElementById("mySpinnerModal").click(); // close spinner
        document.getElementById("trigger-modal").click();
        this.setState({ spinnerOn: false });
        this.refs.btn.removeAttribute("disabled");
    }

    async onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    renderTicketTypes = (ticketType, i) => {
        return (
            <option key={i} value={ticketType.slug}>
                {ticketType.fullName}
            </option>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <div className="App">
                    <h1>Loading...</h1>
                </div>
            )
        }

        const { token, associationTop, spinnerOn, permitMasterAdmin, permitAssociationAdmin, permitAssociationMember } = this.state;

        if (!permitMasterAdmin && !permitAssociationAdmin && !permitAssociationMember) {
            return (
                <div className="App">
                    <h1>Sorry. You are not authorized to view this page.</h1>
                </div>
            )
        }

        var ticketTypes = getTicketTypes();
        ticketTypes.sort((a, b) => (a.slug > b.slug) ? 1 : -1); // arrange alphabetically by slug

        return (
            <div className="App">
                <AssociationTop cbForAssociationTop={associationTop} />

                <section className="ftco-section ftc-no-pb">
                    <TMAdminSideNav token={token} />
                    <TMMemberSideNav />

                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-center">
                            <h3 className="mb-2 bread">Create New Ticket</h3>
                        </div>
                    </div>

                    <section className="ftco-section bg-light">

                        <div className="container">

                            <div className="row">
                                <div className="properties">
                                    <div className="text p-3">
                                        <span className="status"></span>
                                        <div className="d-flex">
                                            <div className="one">
                                                <form onSubmit={this.onFormSubmit} id="ticket_form">
                                                    <div className="form-group">
                                                        <label htmlFor="type">Ticket Type:</label><br />
                                                        <select name="type" className="custom-select" required onChange={this.onChange}>
                                                            <option value="">-- Please Choose --</option>
                                                            {ticketTypes.map((ticketType, i) => {
                                                                return this.renderTicketTypes(ticketType, i);
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="title">Title:</label>
                                                        <input name="title" type="title" className="form-control" placeholder="Enter title" id="title" required minLength="10" onChange={this.onChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="description">Description:</label>
                                                        <textarea name="description" type="description" className="form-control" placeholder="Enter description" id="description" required minLength="2" onChange={this.onChange}></textarea>
                                                    </div>
                                                    <button ref="btn" type="submit" className="FormField__Button mr-20">Submit</button>
                                                    <input type="hidden" id="trigger-modal" data-toggle="modal" data-target="#myModal" />
                                                    <input type="hidden" id="trigger-spinner-modal" data-toggle="modal" data-target="#mySpinnerModal" />
                                                    {spinnerOn ? <SpinnerModal /> : null}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </section>
                <BootstrapModal onClose={this.onClose} cbForBootstrapModal={{ header: this.state.modalHeader, body: this.state.modalBody, classNameValue: this.state.classNameValue }} />

            </div>
        );
    };
}

export default AAAddTicket;
