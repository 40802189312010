import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../assets/css/login.css';
import BootstrapModal from '../components/modals/bootstrap-modal';
import NavButton from '../components/nav-button';
import SpinnerModal from '../components/modals/spinner-modal';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spinnerOn: false,
            email: '',
            password: '',
        };
        this.submit = this.submit.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    async componentDidMount() {
        this.setState({ spinnerOn: true }); // this is required
    }

    onClose() {
        console.log('modal closed...');
    }

    change(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async submit(e) {
        e.preventDefault();
        document.getElementById("trigger-spinner-modal").click(); // open spinner

        const user = {
            // identifier: 'test@test.com',
            // password: '1',
            identifier: this.state.email,
            password: this.state.password
        };

        try {
            // get token from backend
            let result = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/local`, user);
            // console.log('result 1', result);

            if (result.status === 200) {
                // set token
                localStorage.setItem('hoaoa-jwt', result.data.jwt);

                // send email to craig notifying him of user login
                let data = {
                    uid: result.data.user.id,
                    association_id: result.data.user.association_id.id,
                    association_name: result.data.user.association_id.name,
                    email: result.data.user.email,
                    role: result.data.user.role.name,
                };
                // console.log('data', data);
                await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/associations/notify_user_login`, data);

                // redirect to association
                window.location.replace(`/secure/association/${result.data.user.association_id.token}`);
                // document.getElementById("mySpinnerModal").click(); // close spinner
                // this.props.history.push(`/secure/association/${result.data.user.association_id.token}`); // forward the user to his assigned association
            } else {
                this.setState({modalHeader: `Failure`,
                    modalBody: `
                    Something failed. Please contact your administrator.
                    `,
                    classNameValue: 'danger'
                });
                document.getElementById("trigger-status-modal").click();
                console.log('result failure', result);
            }
        } catch (error) {
            localStorage.removeItem('hoaoa-jwt');
            localStorage.removeItem('hoaoa-user');
            document.getElementById("mySpinnerModal").click(); // close spinner

            console.log('An error occurred:', error);
            console.log('error.response', error.response);
            // console.log('error messages', error.response.data['message'][0]['messages']);
            let modalBody = '';
            if (error.response.data.statusCode === 500) {
                console.log('error.response.data.message', error.response.data.message);
                modalBody += `${error.response.data.message}. `
            } else {
                for (let message of error.response.data['message'][0]['messages']) {
                    console.log('message', message);
                    modalBody += `${message.message}. `
                }
            }
            this.setState({modalHeader: `Error ${error.response.status}`,
                modalBody,
                classNameValue: 'danger'
            });
            document.getElementById("trigger-status-modal").click();
        }

        // axios
        // .post(`${process.env.REACT_APP_API_ENDPOINT}/auth/local`, {
        //     // identifier: 'test@test.com',
        //     // password: '1',
        //     identifier: this.state.email,
        //     password: this.state.password
        // })
        // .then(response => {
        //     // Handle success.
        //     localStorage.setItem('hoaoa-jwt', response.data.jwt);
        //     window.location.replace(`/secure/association/${response.data.user.association_id.token}`);
        //     // this.props.history.push('/association/'); // forward the user to his assigned association
        // })
        // .catch(error => {
        //     // Handle error.
        //     console.log('An error occurred:', error);
        //     localStorage.removeItem('hoaoa-jwt');
        //     localStorage.removeItem('hoaoa-user');
        //     document.getElementById("mySpinnerModal").click(); // close spinner
        //     this.setState({modalHeader: `Sorry`,
        //         modalBody: `
        //         Invalid credentials. Please try again or contact your administrator.
        //         `,
        //         classNameValue: 'danger'
        //     });
        //     document.getElementById("trigger-status-modal").click();
        // });

    }

    render() {
        if (this.state.loading) {
            return (
            <div className="App">
              <h1>Loading...</h1>
            </div>
          )
        }
        const {  spinnerOn } = this.state;

        return (
            <div className="FormCenter App">
                {spinnerOn ? <SpinnerModal /> : null}
                <br />
                Please sign in below or<Link to="/register" className="FormField__Link">Register</Link>
                <form onSubmit={e => this.submit(e)} className="FormFields" >
                    <div className="FormField">
                        <label className="FormField__Label" htmlFor="email">E-Mail Address</label>
                        <input autoFocus type="email" id="email" className="FormField__Input" required placeholder="Enter your email" name="email" value={this.state.email} onChange={e => this.change(e)} />
                    </div>
                    <div className="FormField">
                        <label className="FormField__Label" htmlFor="password">Password</label>
                        <input type="password" id="password" className="FormField__Input" required placeholder="Enter your password" name="password" value={this.state.password} onChange={e => this.change(e)} />
                    </div>
                    <div className="FormField">
                        <button type="submit" className="FormField__Button">Sign In</button>
                    </div>
                    <input type="hidden" id="trigger-status-modal" data-toggle="modal" data-target="#status-modal" />
                    <input type="hidden" id="trigger-spinner-modal" data-toggle="modal" data-target="#mySpinnerModal" />
                </form>
                <br />
                <NavButton toPage={`/forgot-password`} access="p" type={`go`} text={`Forgot Password`} />
                <BootstrapModal onClose={this.onClose} cbForBootstrapModal={{header: this.state.modalHeader, body: this.state.modalBody, customID: "status-modal", classNameValue: this.state.classNameValue}}/>
            </div>
        );
    }

}

export default Login;
