import React, { Component } from 'react';

class Contact extends Component {
    constructor(props) {
        super();
        this.state = { data: [] };
    }

    async componentDidMount() {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/pages/5dbb077b292c1f145c8cf032`);
            const json = await response.json();
            this.setState({ data: json });
        }

        render () {
            if (this.state.data.length === 0) return null;
            var page = this.state.data;
            return (
            <div className="App">


                            <section className="ftco-section ftc-no-pb">
                        			<div className="container">
                                    {/*
                        				<div className="row no-gutters">
                        					<div className="col-md-5 p-md-5 img img-2 d-flex justify-content-center align-items-center" /*style="background-image: url(images/about.jpg);">
                        						<a href="https://vimeo.com/45830194" className="icon popup-vimeo d-flex justify-content-center align-items-center">
                        							<span className="icon-play"></span>
                        						</a>
                        					</div>
                                            <div className="col-md-7 wrap-about pb-md-5 ftco-animate post fadeInUp ftco-animated">
                                            */}
                                                <div className="heading-section heading-section-wo-line mb-5 pl-md-5">
                                                    <div className="pl-md-5 ml-md-5">
                                                        <h2 className="mb-4">{page.title}</h2>
                                                    </div>
                                                </div>
                                                <div className="pl-md-5 ml-md-5 mb-5">
                                                    <span dangerouslySetInnerHTML={{__html: page.body}}></span>
                                                </div>
                                                {/*
                                            </div>
                                            </div>
                                            */}
                        			</div>
                    		</section>



            </div>
            );
        }
    }

    export default Contact;
