import React, { Component } from 'react';
import avatarDefault from '../assets/images/avatar-default.png';

import { getHOALeadership } from '../helpers/get';

class Leadership extends Component {
    constructor(props) {
        super();
        this.state = { data: [], directors: [], loading: true };
    }

    async componentDidMount() {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/pages/5e39893d995c1f5b25a73194`);
        const json = await response.json();
        const directors = await getHOALeadership();
        this.setState({ page: json, directors, loading: false });
    }

        render () {
            if (this.state.loading) {
                return (
                <div className="App">
                  <h1>Loading...</h1>
                </div>
              )
            }

            var { page, directors } = this.state;

            if (!directors.length) {
                return (
                    <div className="App">
                        <h1>No directors found...</h1>
                    </div>
                )
            }

            return (
            <div className="App">

                <section className="ftco-section ftc-no-pb">
        			<div className="container">
                        <div className="heading-section heading-section-wo-line mb-5 pl-md-5">
                            <div className="pl-md-5 ml-md-5">
                                <h2 className="mb-4">{page.title}</h2>
                            </div>
                        </div>
                        <div className="pl-md-5 ml-md-5 mb-5">
                            <span dangerouslySetInnerHTML={{__html: page.body}}></span>
                        </div>
        			</div>
        		</section>
                <section className="ftco-section">
                <div className="container">
                <div className="row">
                {directors.map((director, i) => {
                    var director_photo_url = (director.photo && director.photo.url) ? `${process.env.REACT_APP_API_ENDPOINT}${director.photo.url}`: avatarDefault;
                    return (
                       <figure style={{width: "30%", float: "left", position: "relative"}} key={i}>
                         <img src={director_photo_url} alt={director.full_name} className="img-fluid" height="125px" width="125px" />
                         <figcaption>
                         {director.full_name} <br />
                         {director.title} <br />
                         {director.email} <br />
                         </figcaption>
                       </figure>
                    );
                })}
                </div>
                </div>
                </section>

            </div>
            );
        }
    }

    export default Leadership;
