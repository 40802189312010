import React, { Component } from 'react';

// import "react-toggle/style.css" // for ES6 modules
import '../../../assets/css/form.css';

import AssociationTop from '../../../components/association-top';
import BootstrapModal from '../../../components/modals/bootstrap-modal';
import SpinnerModal from '../../../components/modals/spinner-modal';
import TMAdminSideNav from '../../../components/tm-admin-sidenav';

import { getAssociationById, getUnitDocumentTypes, parseAssociationTopFromAssociation, ensureAssociationAdminEmailValues } from '../../../helpers';
import { getAuthUsersMe, getUserByUserId } from '../../../helpers/get';
import { notifyDocumentUploaded } from '../../../helpers/mailers';
import { postUploadUnitDocument } from '../../../helpers/post';
import { permitMasterAdminRole, permitAssociationAdminRole } from '../../../helpers/role_permissions';

class AAAddUnitDocument extends Component {
    constructor(props) {
        super();
        this.state = {
            loading: true, field: null, title: null, notifyMembers: false, file: null,
            modalHeader: null, modalBody: null, classNameValue: null, spinnerOn: false
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    async componentDidMount() {
        const { token, user_id } = this.props.match.params;
        // const unitOwner = await getUserByUserId(user_id);

        const loggedInUser = await getAuthUsersMe();
        const permitMasterAdmin = permitMasterAdminRole(loggedInUser.role.id);
        const permitAssociationAdmin = permitAssociationAdminRole(loggedInUser.role.id);
        const association = await getAssociationById(loggedInUser.association_id);
        const associationTop = parseAssociationTopFromAssociation(association);

        this.setState({ token, user_id, loggedInUser, associationTop, permitMasterAdmin, permitAssociationAdmin, adminuser: association.adminuser, loading: false, spinnerOn: true });
    }

    onClose() {
        console.log('modal closed...');
    }

    async onFormSubmit(e) {
        e.preventDefault() // Stop form submit
        this.refs.btn.setAttribute("disabled", "disabled");
        this.setState({ spinnerOn: true }); // this is required
        document.getElementById("trigger-spinner-modal").click(); // open spinner
        let { user_id, type, title, adminuser, loggedInUser, notifyMembers, file } = this.state;
        notifyMembers = (notifyMembers === 'on') ? true: false;

        const formData = new FormData();
        formData.append('ref', 'unit-document');
        formData.append('field', 'file');
        formData.append('refId', user_id);
        formData.append('user', user_id);
        formData.append('type', type);
        formData.append('title', title);
        formData.append('association', loggedInUser.association_id);
        formData.append('notifyMembers', notifyMembers);
        formData.append('files', file);
        let result = await postUploadUnitDocument(formData);
        if (result.status === 200) {
            // send email to unit owner
            if (notifyMembers === true) {
                const unitOwner = await getUserByUserId(user_id);
                let data = {
                    document_path: 'manage_unit_documents',
                    association_id: unitOwner.association_id.id,
                    association_name: unitOwner.association_id.name,
                    association_token: unitOwner.association_id.token,
                    document_type: type,
                    document_title: title,
                };
                data = await ensureAssociationAdminEmailValues(data, adminuser);
                notifyDocumentUploaded(data);
            }

            document.getElementById("upload_form").reset();
            this.setState({
                modalHeader: `Success!`,
                modalBody: `
                Your file was uploaded successfully.
                `,
                classNameValue: 'success'
            });
        } else {
            this.setState({
                modalHeader: `Sorry`,
                modalBody: `
                Your file did NOT upload.
                `,
                classNameValue: 'warning'
            });
            console.log('result', result);
        }
        document.getElementById("mySpinnerModal").click(); // close spinner
        document.getElementById("trigger-modal").click();
        this.setState({ spinnerOn: false });
        this.refs.btn.removeAttribute("disabled");
    }

    async onChange(e) {
        if (e.target.name === 'file') {
            this.setState({ file: e.target.files[0] })
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    renderUnitDocumentTypes = (unitDocumentTypes, i) => {
        return (
            <option key={i} value={unitDocumentTypes.slug}>
                {unitDocumentTypes.fullName}
            </option>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <div className="App">
                    <h1>Loading...</h1>
                </div>
            )
        }
        const { token, associationTop, loggedInUser, permitMasterAdmin, permitAssociationAdmin, spinnerOn } = this.state;

        if (!permitMasterAdmin && !permitAssociationAdmin) {
            return (
                <div className="App">
                    <h1>Sorry. You are not authorized to view this page.</h1>
                </div>
            )
        }

        var unitDocumentTypes = getUnitDocumentTypes();
        unitDocumentTypes.sort((a, b) => (a.slug > b.slug) ? 1 : -1); // arrange alphabetically by slug

        return (
            <div className="App">
                <AssociationTop cbForAssociationTop={associationTop} />

                <section className="ftco-section ftc-no-pb">
                    <TMAdminSideNav token={token} />

                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-center">
                            <h3 className="mb-2 bread">Create New {loggedInUser.unitNumber} File</h3>
                        </div>
                    </div>

                    <section className="ftco-section bg-light">

                        <div className="container">

                            <div className="row">
                                <div className="properties">
                                    <div className="text p-3">
                                        <span className="status"></span>
                                        <div className="d-flex">
                                            <div className="one">
                                                <form onSubmit={this.onFormSubmit} id="upload_form">
                                                    <div className="form-group">
                                                        <label htmlFor="type">Document Type:</label><br />
                                                        <select name="type" className="custom-select" required onChange={this.onChange}>
                                                            <option value="">-- Please Choose --</option>
                                                            {unitDocumentTypes.map((unitDocumentType, i) => {
                                                                return this.renderUnitDocumentTypes(unitDocumentType, i);
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="title">Title:</label>
                                                        <input name="title" type="title" className="form-control" placeholder="Enter title" id="title" required onChange={this.onChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="upload">Upload:</label>
                                                        <input name="file" type="file" className="form-control" id="file" required onChange={this.onChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="notifyMembers">Notify Members: </label>
                                                        <input name="notifyMembers" type="checkbox" className="FormField__Checkbox" id="notifyMembers" onChange={this.onChange} />
                                                    </div>
                                                    <input type="hidden" id="trigger-modal" data-toggle="modal" data-target="#myModal" />
                                                    <button ref="btn" type="submit" className="FormField__Button mr-20">Submit</button>
                                                    <input type="hidden" id="trigger-spinner-modal" data-toggle="modal" data-target="#mySpinnerModal" />
                                                    {spinnerOn ? <SpinnerModal /> : null}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </section>
                <BootstrapModal onClose={this.onClose} cbForBootstrapModal={{ header: this.state.modalHeader, body: this.state.modalBody, classNameValue: this.state.classNameValue }} />

            </div>
        );
    };
}

export default AAAddUnitDocument;
