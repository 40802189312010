import { getJwt } from '../helpers';
const method = 'DELETE';
const jwt = getJwt();

export async function deleteUpload(document_file_id) {
    var data = {
        method,
        headers: {
            // Accept: 'application/json',
            // 'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    };
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/upload/files/${document_file_id}`, data);
    // console.log('deleteUpload response', response);
    return {message: "deleteUpload response", status: response.status};
    // return await response.json(); // this returns the upload object
}
