import React, { Component } from 'react';
import { MDBInput } from "mdbreact";
import { NavLink } from "react-router-dom"

import "react-toggle/style.css" // for ES6 modules
import '../../assets/css/manage_members.css';

import AssociationTop from '../../components/association-top';
import ConfirmDeleteFileModal from '../../components/modals/confirm-delete-file-modal';
import ModalDisplayDocument from '../../components/modals/display-document';
import SpinnerModal from '../../components/modals/spinner-modal';
import TMAdminSideNav from '../../components/tm-admin-sidenav';

import {
  getAssociationById, getAssociationByToken, getDocumentTypes, parseAssociationTopFromAssociation,
  parseDocumentsFromAssociation, removeDocumentFromGroupByDocumentID
} from '../../helpers';
import { deleteUpload } from '../../helpers/delete';
import { getAuthUsersMe } from '../../helpers/get';
import { updateAssociationWithModifiedComponent } from '../../helpers/put';
import { permitMasterAdminRole, permitAssociationAdminRole } from '../../helpers/role_permissions';

class AAManageUploads extends Component {
  constructor(props) {
    super();
    var document = {
      id: null,
      title: null,
      // username: null,
      // confirmed: false,
      // blocked: true,
    };
    this.state = {
      search: "", loading: true, spinnerOn: false,
      isOpen: false,
      isOpenDeleteModal: false,
      document, allRoles: [], result_response: {}
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.document_tag !== prevState.document_tag) {
      return { document_tag: nextProps.match.params.document_tag };
    }
    else {
      return null;
    }
  }

  async componentDidMount() {
    const { token } = this.props.match.params;

    const loggedInUser = await getAuthUsersMe();
    const permitMasterAdmin = await permitMasterAdminRole(loggedInUser.role.id);
    const permitAssociationAdmin = await permitAssociationAdminRole(loggedInUser.role.id);

    let association;
    if (permitMasterAdmin) {
      association = await getAssociationByToken(token);
      association = association[0];
      association = await getAssociationById(association._id);
    } else {
      association = await getAssociationById(loggedInUser.association_id);
    }
    let allAssociationDocuments = await parseDocumentsFromAssociation(association);

    const associationTop = await parseAssociationTopFromAssociation(association);

    var documentTypes = getDocumentTypes();

    this.setState({ token, association, associationTop, documentTypes, permitMasterAdmin, permitAssociationAdmin, allAssociationDocuments, loading: false, spinnerOn: true });
  }

  toggleModal = (ev = null) => {
    let values = {};
    if (ev.currentTarget.value) values = JSON.parse(ev.currentTarget.value);
    this.setState({
      isOpen: !this.state.isOpen,
      document_name: values.name || '',
      document_url: values.url || '',
      document_type: values.type || ''
    });
  }

  async toggleDeleteModal(ev = null) {
    let values = {};
    if (ev.currentTarget.value) values = JSON.parse(ev.currentTarget.value);
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      documentGroupSlug: values.documentGroupSlug,
      document_file_id: values.document_file_id,
      document_name: values.name || '',
      modalHeader: `Are you sure?`,
      modalBody: `
            Please confirm you want to delete file "${values.name}".
            `,
      classNameValue: 'warning',
    });
  }

  async onClickDelete(ev = null) {
    this.setState({ spinnerOn: true }); // this is required
    document.getElementById("trigger-spinner-modal").click(); // open spinner
    let values = {};
    if (ev.currentTarget.value) values = JSON.parse(ev.currentTarget.value);
    this.setState({
      modalHeader: `Are you sure?`,
      modalBody: `
            Please confirm you want to delete file "${values.name}".
            `,
      classNameValue: 'warning'
    });
    document.getElementById(`trigger-modal-${values.document_file_id}`).click();
  }

  onDeleteCallback = async (dataFromChild) => {
    var { spinnerOn, removeDocumentBlock } = dataFromChild;
    if (!removeDocumentBlock) return; // delete not confirmed

    this.setState({ spinnerOn }); // this is required
    let { association, document_name, document_file_id, documentGroupSlug } = this.state;

    // remove item from display
    let element = document.getElementById(`document-block-${document_file_id}`);
    element.parentNode.removeChild(element);

    // deleting a file requires deleting the component relationship in the association and then deleting the actual file upload record
    // 1. delete component relationship from association
    association[documentGroupSlug] = removeDocumentFromGroupByDocumentID(association[documentGroupSlug], document_file_id);
    const response1 = await updateAssociationWithModifiedComponent(association);
    // console.log('response1', response1);
    if (response1.status === 200) { // component relationship deletion success
      // 2. delete file
      const response2 = await deleteUpload(document_file_id);
      // console.log('response2', response2);
      if (response2.status === 200) { // upload deletion success
        // document.getElementById("mySpinnerModal").click(); // close spinner
        this.setState({
          modalHeader: `Deleted!`,
          modalBody: `
                    Your file "${document_name}" was deleted successfully.
                    `,
          classNameValue: 'success',
          confirmAction: null
        });
      } else {
        this.setState({
          modalHeader: `Sorry (2)`,
          modalBody: `
                    Something went wrong with deleting the file record.
                    `,
          classNameValue: 'danger',
          confirmAction: null
        });
      }
    } else {
      this.setState({
        modalHeader: `Sorry (1)`,
        modalBody: `
                Something went wrong with deleting the file from the association.
                `,
        classNameValue: 'danger',
        confirmAction: null
      });
    }
    // document.getElementById("trigger-modal").click();
  }

  renderDocuments = (document, i) => {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }
    var { search } = this.state;

    if (search !== "" && document.title.toLowerCase().indexOf(search.toLowerCase()) === -1) {
      return null
    }

    if (document === null) return (<div key={i}>null</div>);
    if (document.file === undefined || document.file === null) return (<div key={i}>undefined</div>);

    let showUnit = (document.documentGroupSlug === 'unit_documents') ? `Unit Number: ${document.unit_number}` : '';
    let clean_ext = document.file.ext.replace('.', '');
    let values = { url: document.file.url, type: clean_ext, name: document.file.name };
    let deleteValues = { name: document.file.name, document_file_id: document.file.id, documentGroupSlug: document.documentGroupSlug };
    return (
      <div className="row" id={`document-block-${document.file.id}`} key={i}>
        <div className="properties">
          <div className="text p-3">
            <span className="status"></span>
            <div className="d-flex">
              <div className="one">
                <strong>{document.title}</strong>
                <br />
                {showUnit}
                {document.documentGroupSlug === 'unit_documents' ? <br /> : null}
                Created: {document.file.createdAt}
                <br />
                Id: {document.file.id}
                <br />
                File Name: {document.file.name}
                <br />
                Size: {document.file.size} KB
                <br />
                Type: {clean_ext}
                <br />
                <br />
                <button key={i} value={JSON.stringify(values)} onClick={this.toggleModal} className="btn btn-info mr-2" >
                  View
                </button>
                <button key={document.file.id} onClick={this.toggleDeleteModal} value={JSON.stringify(deleteValues)} className="btn btn-danger mr-2" >
                  Delete
                </button>

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDocumentGroups = (documentGroup, i) => {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }
    let { search, allAssociationDocuments } = this.state;

    if ((search !== "" && !allAssociationDocuments[documentGroup.slug].length) ||
      (search !== "" && allAssociationDocuments[documentGroup.slug][0].title.toLowerCase().indexOf(search.toLowerCase()) === -1)) {
      return null
    }

    return (
      <div key={i}>
        <div className="row no-gutters slider-text align-items-left">
          <h4 className="mb-3 bread">{documentGroup.fullName} ({allAssociationDocuments[documentGroup.slug].length})</h4>
        </div>

        {allAssociationDocuments[documentGroup.slug].map((document, i) => {
          if (document === null) return (<div key={i}>Sorry, There is a problem with a document.</div>);
          document.documentGroupSlug = documentGroup.slug;
          return this.renderDocuments(document, i);
        })}
      </div>
    );
  };

  onchange = e => {
    this.setState({ search: e.target.value });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      )
    }

    var { token, permitMasterAdmin, permitAssociationAdmin, associationTop, spinnerOn } = this.state;
    if (!permitMasterAdmin && !permitAssociationAdmin) {
      return (
        <div className="App">
          <h1>Sorry. You are not authorized to view this page.</h1>
        </div>
      )
    }

    this.state.documentTypes.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1); // arrange alphabetically by name

    return (
      <div className="App">
        {spinnerOn ? <SpinnerModal /> : null}
        <input type="hidden" id="trigger-spinner-modal" data-toggle="modal" data-target="#mySpinnerModal" />

        <AssociationTop cbForAssociationTop={associationTop} />
        <section className="ftco-section ftc-no-pb">
          <TMAdminSideNav token={token} />

          <div className="container">
            <div className="row no-gutters slider-text align-items-center justify-content-center">
              <h3 className="mb-3 bread">Manage Uploads</h3>
            </div>
          </div>

          <section className="ftco-section bg-light">

            <div className="container">
              <MDBInput
                hint="Search Document Title"
                icon="search"
                onChange={this.onchange}
              />
              <br />
              <NavLink to={`/secure/${token}/add/upload`} className="btn btn-success" role="button">Create New Document</NavLink>
              <br />

              {this.state.documentTypes.map((documentGroup, i) => {
                return this.renderDocumentGroups(documentGroup, i);
              })}

              <ModalDisplayDocument show={this.state.isOpen}
                onClose={this.toggleModal}
              >
                <iframe width="100%" height="750" title={this.state.document_name} src={`https://docs.google.com/gview?url=${process.env.REACT_APP_API_ENDPOINT}${this.state.document_url}&embedded=true`} type={`application/${this.state.document_type}`} ></iframe>
              </ModalDisplayDocument>

              <ConfirmDeleteFileModal
                show={this.state.isOpenDeleteModal}
                onCloseDeleteModal={this.toggleDeleteModal}
                onDeleteCallback={this.onDeleteCallback}
                cbForBootstrapModal={{
                  association: this.state.association, header: this.state.modalHeader, body: this.state.modalBody,
                  classNameValue: this.state.classNameValue, customID: `myModal-${this.state.document_file_id}`,
                  confirmAction: "onClickConfirmDelete", documentGroupSlug: this.state.documentGroupSlug, id: this.state.document_file_id
                }}
              />

            </div>
          </section>
        </section>

      </div>
    );
  };
}

export default AAManageUploads;
