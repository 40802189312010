
export function getAccountantRoleId() {
    return '5dd567b9f4e5c11559bdba44';
}

export function getAssociationAdminRoleId() {
    return '5dd56783f4e5c11559bdb9f0';
}

export function getAssociationMemberRoleId() {
    return '5e831193d4cc9d561fa13368';
}

export function getMaintenanceManagerRoleId() {
    return '5dd567d8f4e5c11559bdba98';
}

export function getMasterAdminRoleId() {
    return '5fa4358dac45ed11b5eb7cdb';
}

export function permitAccountantRole(role_id) {
    if (role_id === getAccountantRoleId()) {
        return true;
    }
    return false;
}

export function permitAssociationAdminRole(role_id) {
    if (role_id === getAssociationAdminRoleId()) {
        return true;
    }
    return false;
}

export function permitAssociationMemberRole(role_id) {
    if (role_id === getAssociationMemberRoleId()) {
        return true;
    }
    return false;
}

export function permitMaintenanceManagerRole(role_id) {
    if (role_id === getMaintenanceManagerRoleId()) {
        return true;
    }
    return false;
}

export function permitMasterAdminRole(role_id) {
    if (role_id === getMasterAdminRoleId()) {
        return true;
    }
    return false;
}

export function parseRoleNameById(role_id) {
    if (permitAccountantRole(role_id)) return 'Accountant';
    if (permitAssociationAdminRole(role_id)) return 'Association Administrator';
    if (permitAssociationMemberRole(role_id)) return 'Association Member';
    if (permitMaintenanceManagerRole(role_id)) return 'Maintenance Manager';
    if (permitMasterAdminRole(role_id)) return 'Master Admin';
    return 'No Role Found';
}

export function parseAssociationRolesById(roles, user_role_id) {
    return roles.filter(role => parseRoleNameById(role.id) !== 'No Role Found' || role.id === user_role_id);
}
