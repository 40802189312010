import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

import AssociationTop from '../components/association-top';
import TMAdminSideNav from '../components/tm-admin-sidenav';
import TMMemberSideNav from '../components/tm-member-sidenav';

import { getAssociationById, parseAssociationTopFromAssociation } from '../helpers';
import { getAuthUsersMe } from '../helpers/get';
import { permitMasterAdminRole, permitAssociationAdminRole, permitAssociationMemberRole } from '../helpers/role_permissions';

class UserPage extends Component {
    constructor(props) {
        super();
        this.state = { user: {}, permitAssociationAdmin: false, permitAssociationMember: false, loading: true };
    }

    async componentDidMount() {
        const loggedInUser = await getAuthUsersMe();
        const permitMasterAdmin = await permitMasterAdminRole(loggedInUser.role.id);
        const permitAssociationAdmin = await permitAssociationAdminRole(loggedInUser.role.id);
        const permitAssociationMember = await permitAssociationMemberRole(loggedInUser.role.id);
        let association = await getAssociationById(loggedInUser.association_id)
        let associationTop = await parseAssociationTopFromAssociation(association);
        this.setState({ loggedInUser, permitMasterAdmin, permitAssociationAdmin, permitAssociationMember, associationTop, loading: false });
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="App">
                    <h1>Loading...</h1>
                </div>
            )
        }

        let { loggedInUser, associationTop, permitMasterAdmin, permitAssociationAdmin, permitAssociationMember } = this.state;
        if (!permitMasterAdmin && !permitAssociationAdmin && !permitAssociationMember) {
            return (
                <div className="App">
                    <h1>Sorry. You are not authorized to view this page.</h1>
                </div>
            )
        }

        return (
            <div className="App">
                <AssociationTop cbForAssociationTop={associationTop} />
                <section className="ftco-section ftc-no-pb">
                    <TMAdminSideNav token={associationTop.token} />
                    <TMMemberSideNav />

                    <section className="ftco-section ftc-no-pb">
                        <div className="container">
                            <div className="heading-section heading-section-wo-line mb-5">
                                <h2 className="mb-4">{loggedInUser.username}</h2>
                                <h3 className="mb-4">{loggedInUser.role.name}</h3>
                            </div>
                            <div className="mb-5">
                                <span dangerouslySetInnerHTML={{ __html: loggedInUser.email }}></span>
                                <NavLink to={`/secure/${associationTop.token}/manage_unit_documents/${loggedInUser._id}`} className="nav-link" activeClassName='active'>View Your Unit Documents</NavLink>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        );
    }
}

export default UserPage;
