import { doLogout } from '../helpers/auth';
import { getAuthUsersMe } from '../helpers/get';
import { updateAssociationWithModifiedComponent } from '../helpers/put';
import { parseRoleNameById } from '../helpers/role_permissions';
import jwt_decode from 'jwt-decode';
const method = 'GET';
const jwt = getJwt();

export function getJwt() {
    const jwt = localStorage.getItem('hoaoa-jwt');
    if (jwt) {
        return 'Bearer ' + localStorage.getItem('hoaoa-jwt');
    }
    return null;
};

export function getJwtDecoded() {
    const jwt = localStorage.getItem('hoaoa-jwt');
    if (jwt) {
        return jwt_decode(localStorage.getItem('hoaoa-jwt'));
    }
    return null;
};

export async function setAuthUserDetails() {
    var user = localStorage.getItem('hoaoa-user');
    if (!user || !user.id || !user.username || !user.role.name) {
        const jwt = getJwt();
        if (jwt) {
            user = await getAuthUsersMe();
            if (user.statusCode === 401) {
                return doLogout();
            }
            user.role = user.role || {};
            localStorage.setItem('hoaoa-user', JSON.stringify({
                id: user.id,
                username: user.username,
                role: user.role.name || '',
            }));
        }
    }
    return null;
};

export function getAuthUserDetails() {
    let user = JSON.parse(localStorage.getItem('hoaoa-user'));
    // console.log('user', user);
    return user;
};

export function getDocumentTypes() {
    return [
        { slug: 'notices', fullName: 'NOTICES' , access: 'secure', className: 'urgent' },
        { slug: 'financial_statements', fullName: 'Financial Statements' , access: 'secure', className: '' },
        { slug: 'budgets', fullName: 'Budgets' , access: 'secure', className: '' },
        { slug: 'board_meeting_minutes', fullName: 'Board Meeting Minutes' , access: 'secure', className: '' },
        { slug: 'membership_meeting_minutes', fullName: 'Membership Meeting Minutes' , access: 'secure', className: '' },
        { slug: 'contractor_agreements', fullName: 'Contractor Agreements' , access: 'secure', className: '' },
        { slug: 'director_certifications', fullName: 'Director Certifications' , access: 'secure', className: '' },
        { slug: 'disclosures', fullName: 'Disclosures' , access: 'secure', className: '' },
        { slug: 'insurance', fullName: 'Insurance' , access: 'secure', className: '' },
        { slug: 'policies_and_procedures', fullName: 'Policies and Procedures' , access: 'secure', className: '' },
        { slug: 'unit_documents', fullName: 'Unit Documents' , access: 'secure', className: '' },

        // { slug: 'public', fullName: 'Public' , access: 'public', className: '' }, // seems like there are files stored in public that need to be purged
        { slug: 'forms', fullName: 'Forms' , access: 'public', className: '' },
        { slug: 'declarations', fullName: 'Declarations' , access: 'public', className: '' },
        { slug: 'amendments', fullName: 'Amendments' , access: 'public', className: '' },
        { slug: 'by_laws', fullName: 'By-Laws' , access: 'public', className: '' },
        { slug: 'articles_of_incorporation', fullName: 'Articles of Incorporation' , access: 'public', className: '' },
        { slug: 'rules_and_regulations', fullName: 'Rules & Regulations' , access: 'public', className: '' },
    ];
}

export function getAccountingDocuments() {
    return [
        { slug: 'Professional_Accounting_Services_951b5e60b6.pdf', fullName: 'Accounting Services' , access: 'public', className: '' },
        { slug: 'Chart_of_Accounts_e60138a726.pdf', fullName: 'Chart of Accounts' , access: 'public', className: '' },
        { slug: 'Benchmarking_e0aee1a595.pdf', fullName: 'Benchmarking' , access: 'public', className: '' },
        { slug: 'Tax_Returns_2f69c4e940.pdf', fullName: 'Tax Returns' , access: 'public', className: '' },
        { slug: 'Bookkeeping_Standards_5c5f7c7fa1.pdf', fullName: 'Bookkeeping Standards' , access: 'public', className: '' },
    ];
}

export function getAdministrationDocuments() {
    return [
        { slug: 'Community_Websites_192da50c8f.pdf', fullName: 'Community Websites' , access: 'public', className: '' },
        { slug: 'Reserve_Requirements_83b41dec09.pdf', fullName: 'Reserve Requirements' , access: 'public', className: '' },
        { slug: 'Insurance_Requirements_07936f3d16.pdf', fullName: 'Insurance Requirements' , access: 'public', className: '' },
        { slug: 'Consulting_9ee2b4f6ee.pdf', fullName: 'Consulting Services' , access: 'public', className: '' },
        { slug: 'Templates_e649da05f9.pdf', fullName: 'Templates' , access: 'public', className: '' },
    ];
}

export function getUnitDocumentTypes() {
    // access is always secure to either the association or the unit
    return [
        { slug: 'approval', fullName: 'Approval' , access: 'association', className: '' },
        { slug: 'account_statement', fullName: 'Account Statement' , access: 'association', className: '' },
        { slug: 'violation_letter', fullName: 'Violation Letter' , access: 'association', className: '' },

    ];
}

export function getTicketTypes() {
    return [
        { slug: 'work_order', fullName: 'Work Order' , access: 'public', className: '' },
        { slug: 'information_request', fullName: 'Information Request' , access: 'public', className: '' },
        { slug: 'report_violation', fullName: 'Report A Violation' , access: 'public', className: '' },
    ];
}

export function getTicketStatuses() {
    return [
        { slug: 'new', fullName: 'New' , access: 'public', className: '' },
        { slug: 'assigned', fullName: 'Assigned' , access: 'public', className: '' },
        { slug: 'completed', fullName: 'Completed' , access: 'public', className: '' },
        { slug: 'postponed', fullName: 'Postponed' , access: 'public', className: '' },
        { slug: 'canceled', fullName: 'Canceled' , access: 'public', className: '' },
    ];
}

export function parseValueBySlug(object, slug, returnField) {
    return object.filter(row => row.slug === slug)[0][returnField];
}

export function parseTicketsByType(tickets) {
    const ticketTypes = getTicketTypes();
    const typeNames = ticketTypes.map(a => a.slug);
    let adjustedTickets = [];
    for (let item of tickets) {
        if (!adjustedTickets[item.type]) adjustedTickets[item.type] = [];
        if (typeNames.indexOf(item.type) !== -1) {
            adjustedTickets[item.type].push(item);
        }
    }
    return adjustedTickets;
}

export function parseTicketsByUserId(tickets, user_id) {
    let adjustedTickets = [];
    for (let item of tickets) {
        if (item.created_by_user._id === user_id) {
            adjustedTickets.push(item);
        }
    }
    return adjustedTickets;
}

export async function parseDocumentsFromAssociation(association) {
    const documentTypes = getDocumentTypes();
    const documentNames = documentTypes.map(a => a.slug);
    let documents = {};
    let update_association = 0;
    for (let item in association) {
        if (documentNames.indexOf(item) !== -1) {
            documents[item] = association[item];
            let pre_length = documents[item].length;
            documents[item] = documents[item].filter(function (el) {
                return el != null;
            });
            let post_length = documents[item].length;
            if (pre_length > post_length) {
                update_association = pre_length - post_length;
                association[item] = documents[item];
            }
        }
    }
    if (update_association) {
        await updateAssociationWithModifiedComponent(association);
    }
    documents['token'] = association['token'];
    return documents;
}

export function parseDocumentFromAssociationByID(association, document_id) {
    const documentTypes = getDocumentTypes();
    const documentNames = documentTypes.map(a => a.slug);
    for (let item in association) {
        if (documentNames.indexOf(item) !== -1) {
            for (let document of association[item]) {
                if (document._id === document_id) {
                    return {item, document};
                }
            }
        }
    }
    return null;
}

export function removeDocumentFromGroupByDocumentID(documentGroup, document_file_id) {
    return documentGroup.filter(function( obj ) {
        return obj.file.id !== document_file_id;
    });
}

export function parseDocumentsFromUnitDocuments(unitDocuments) {
    const documentTypes = getUnitDocumentTypes();
    const documentNames = documentTypes.map(a => a.slug);
    let documents = [];
    for (let item of unitDocuments) {
        if (!documents[item.type]) documents[item.type] = [];
        if (documentNames.indexOf(item.type) !== -1) {
            documents[item.type].push(item);
        }
    }
    return documents;
}


export function parseAssociationTopFromAssociation(association) {
    return {
        hero_image_url: association.hero_image_url || association.hero_image.url,
        token: association.token,
        name: association.name
    };
}

export function ensureSecureAuthPath(props, access, jwt, redirect_path) {
    // redirect user to secure path if logged in and using public path
    if (access === 'public' && jwt !== null) {
        console.log('redirecting to secure');
        return props.history.push(`/secure/${redirect_path}`); // forward the user to his assigned association
    }
}

export function ensurePublicUnAuthPath(props, access, jwt, redirect_path, auth_token = null) {
    // redirect user to public path if NOT logged in and using secure path
    if (access !== 'private' && access !== 'secure' && access !== 'public') {
        console.log('redirecting to public');
        return props.history.push(`/public/${redirect_path}`); // forward the user to his assigned association
    } else if (access === 'private' && auth_token === null) {
        console.log('redirecting to login');
        return props.history.push(`/login?target=/public/${redirect_path}`); // forward the user to his assigned association
    } else if (access === 'secure' && jwt === null) {
        console.log('redirecting to login');
        return props.history.push(`/login?target=/public/${redirect_path}`); // forward the user to his assigned association
    }
}

export async function getSelectableAssociations() {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/selectable_associations`);
    return await response.json();
}

export async function getAssociationById(association_id) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/${association_id}`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function getAssociationDocumentsByTypeById(association_id, document_tag, is_access_private = null, document_id = null) {
    let runFetch = false;
    for (let type of getDocumentTypes()) {
        if (
            (jwt !== null) // provide all documents for secure users
            ||
            (type.access === 'public' && type.slug === document_tag) // only provide public documents for public users
            ||
            (is_access_private === 'private' && document_id && type.slug === document_tag) // only provide specific document per private document id provided
        ) {
            runFetch = true;
        }
    }

    // prevent fetch from running
    if (!runFetch) {
        return [{}];
    }

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/documents/${document_tag}/${association_id}`);
    let res2 = await response.json();
    // requires display of newest first per tkt 113
    res2.sort((a, b) => new Date(b.file.createdAt).getTime() - new Date(a.file.createdAt).getTime());
    if (is_access_private === 'private' && document_id) {
        res2.filter((r) => r.id === document_id);
    }
    return res2;
}

export async function getAssociationDocumentsCountById(association_id) {
    const documentMap = getDocumentTypes().map(a => a.slug);

    let body = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(documentMap)
    };
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/documents_count/${association_id}`, body);
    return await response.json();
}

export async function getAssociationUnitsDocumentsCountById(association_id) {
    const documentMap = getDocumentTypes().map(a => a.slug);

    let body = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(documentMap)
    };
    let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/unit-documents/countById/${association_id}`, body);
    response = await response.json();
    return response.result;
}

export async function getAssociationByToken(token) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/token/${token}`);
    return await response.json();
}

export async function getAssociationsByZipcode(zipcode) {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/associations/zipcode/${zipcode}`);
    return await response.json();
}

export async function getAllRoles() {
    const jwt = getJwt();
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users-permissions/roles`, {
        method,
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
    return await response.json();
}

export async function ensureAssociationAdminEmailValues(data, adminuser) {
    data.admin_firstname = adminuser.firstName || 'HOAOA';
    data.admin_lastname = adminuser.lastName || 'Admin';
    data.admin_email = adminuser.email || 'no-reply@hoaoa.org';
    data.signature = (adminuser.firstName) ? `${adminuser.firstName} ${adminuser.lastName}`: 'The Homeowner and Condominium Associations of America';
    const role_id = adminuser.role.id || adminuser.role;
    data.admin_role = parseRoleNameById(role_id); // register form submits format adminuser.role.id while others use adminuser.role
    return data;
}

export async function redirectUserToAssignedAssociationByToken(user, json, redirect_path, document_tag = '') {
    // redirect to secure user token url if provided url token doesnt match the users assigned token
    if (user.association_id !== json[0]._id) {
        const json = await getAssociationById(user.association_id);
        console.log('redirecting to assigned association');
        // return this.props.history.push(`/secure/${redirect_path}${json.token}${document_tag}`); // forward the user to his assigned association
        window.location.replace(`/secure/${redirect_path}${json.token}${document_tag}`);
    }
}

export async function requireMatchingUserToken(jwt, access, token, redirect_path, document_tag = '') {

    // require matching user token if path contains 'secure'
    var json = null;
    if (jwt !== null) {
        console.log('i am secure....');
        const user = await getAuthUsersMe();
        json = await getAssociationByToken(token);

        // skip association redirection for master admin
        if (user.role.name === 'Master Admin') return json[0];

        redirectUserToAssignedAssociationByToken(user, json, redirect_path, document_tag);
        return await getAssociationById(user.association_id);
    } else {
        json = await getAssociationByToken(token);
        return json[0];
        // return await getAssociationById(json[0]._id);
    }
}

export function parseDateTime(datetime) {
    let result = new Date(datetime);
    let date = result.toDateString();
    let time = result.toTimeString();
    time = time.split('GMT');
    return `${date} ${time[0]}`
}

export function sanitizeResponse(response) {
    // console.log('sanitizeResponse', response);
    if (typeof response.associations !== undefined) {
        // console.log('response.associations', response.associations);
        delete response.associations;
    }
    if (typeof response.user !== undefined) {
        // console.log('response.user', response.user);
        delete response.user;
    }
    return response;
}
